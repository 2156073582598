import dayjs from "dayjs";

export const kassaTransactionColumns = () => {
  return [
    {
      flex: 1,
      minWidth: 70,
      field: 'id',
      sortable: false,
      renderHeader: () => <strong>#</strong>,
    },
    {
      flex: 1,
      minWidth: 180,
      field: 'dateCreated',
      disableColumnMenu: false,
      sortable: true,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderCell: (params) => (
        <div>
          <time>{dayjs(params.row.dateCreated).format('DD/MM/YYYY HH:mm')}</time>
        </div>
      ),
      renderHeader: () => <strong>Дата создания</strong>,
    },
    {
      flex: 1,
      minWidth: 180,
      field: 'dateTransaction',
      disableColumnMenu: false,
      sortable: true,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderCell: (params) => (
        <div>
          <time>{dayjs(params.row.dateTransaction).format('DD/MM/YYYY HH:mm')}</time>
        </div>
      ),
      renderHeader: () => <strong>Дата транзакции</strong>,
    },
    {
      flex: 1,
      minWidth: 150,
      field: 'author',
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Создатель</strong>,
    },
    {
      flex: 1,
      field: 'balanceBefore',
      minWidth: 140,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Сальдо начало</strong>,
      valueFormatter: (params) => params.value ? params.value.toLocaleString('ru-RU') : '0',
    },
    {
      flex: 1,
      field: 'summaPlus',
      minWidth: 100,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Поступило</strong>,
      valueGetter: (params) => (params.row.summa > 0 ? params.row.summa : 0),
      valueFormatter: (params) => params.value ? params.value.toLocaleString('ru-RU') : '0',
    },
    {
      flex: 1,
      field: 'summaMinus',
      minWidth: 100,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Списано</strong>,
      valueGetter: (params) => (params.row.summa < 0 ? Math.abs(params.row.summa) : 0),
      valueFormatter: (params) => params.value ? params.value.toLocaleString('ru-RU') : '0',
    },
    {
      flex: 1,
      field: 'balanceAfter',
      minWidth: 140,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Сальдо конец</strong>,
      valueFormatter: (params) => params.value ? params.value.toLocaleString('ru-RU') : '0',
    },
    {
      flex: 1,
      minWidth: 250,
      field: 'type',
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderCell: (params) => (
        <div>
          {params.row.type.name}
        </div>
      ),
      renderHeader: () => <strong>Тип</strong>,
    },
    {
      minWidth: 700,
      flex: 1,
      field: 'comment',
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Описание</strong>,
    },
    {
      flex: 1,
      field: 'kassa',
      minWidth: 200,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderCell: (params) => (
        <div>
          {params.row.kassa.name}
        </div>
      ),
      renderHeader: () => <strong>Касса</strong>,
    },
    {
      field: 'actions',
      sortable: false,
      width: 100,
      menubar: false,
      renderCell: (params) => (
        <div>
          Скоро...
          {/* <Tooltip title='Детали' arrow>
            <Link
              to={`/kassa/details/${params.id}`}
              target='_blank'
            >
              <IconButton aria-label='edit'>
                <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
              </IconButton>
            </Link>
          </Tooltip> */}
          {/* {roleType === 'admin' && (
            <Tooltip title='Редактировать' arrow>
              <Link
                to={`/kassa/update/${params.id}`}
                target='_blank'
              >
                <IconButton aria-label='edit'>
                  <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
          )} */}
        </div>
      ),
      renderHeader: () => <strong>Действия</strong>,
    }
  ]
};