import axios from "axios";
import { handleSignOut } from "../../../configs";

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      handleSignOut()
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      throw new Error('Refresh токен отсутствует');
    }

    const response = await axios.post('https://aylex.ru/api/token/refresh/', {
      refresh: refreshToken,
    });

    if (isTokenExpired(refreshToken)) {
      handleSignOut()
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      throw new Error('Refresh токен истёк');
    }


    if (response.status === 200 && response.data.access) {
      localStorage.setItem('authToken', response.data.access);
      return response.data.access;
    } else {
      throw new Error('Не удалось обновить токен');
    }
  } catch (error) {
    console.error('Ошибка обновления токена:', error);
    handleSignOut()
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    throw error;
  }
};


export const getAuthToken = async () => {
  const token = localStorage.getItem('authToken');

  if (!token || isTokenExpired(token)) {
    return await refreshToken();
  }
  return token;
};

const isTokenExpired = (token) => {
  try {
    const decoded = JSON.parse(atob(token.split('.')[1]));
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Ошибка при проверке истечения токена:', error);
    return true;
  }
};

export const verifyToken = async () => {
  try {
    const token = localStorage.getItem('authToken');

    if (!token || isTokenExpired(token)) {
      return await refreshToken();
    }

    await axios.post('https://aylex.ru/api/token/verify/', { token });
    return token;

  } catch (error) {
    console.error('Ошибка проверки токена:', error);
    return await refreshToken();
  }
};
