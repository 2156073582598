import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import { Controller, useForm } from 'react-hook-form';
import BalanceCard from './../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import { getDocs, query, where, getCountFromServer, orderBy } from 'firebase/firestore';
import { completedOrdersRef, orderRef, returnedOrderRef } from '../../../../utils/collectionRefs';
import OrderGrid from '../../../../components/OrderGrid ';
import { Loader } from '../../../../components/Loader/Loader';
import { Button, FormControlLabel, Radio, RadioGroup, Stack, TextField, Tooltip } from '@mui/material';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { orderColumns } from '../Orders/helpers/orderColumns';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { statusLabels } from '../../../../utils';
import { returnedOrderStatusLabels } from '../Orders/constants';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { AllOrderColumns } from './helpers/allOrdesColumn';
import { handleCopyOrder } from '../Orders/helpers/handleCopyOrder';
import { ExportToExcelButtons } from './helpers/ExportExcel';

const AllOrders = ({ pageTitle }) => {
  const { handleSubmit, control, reset, setValue } = useForm({ mode: 'onChange' });
  const [aggregates, setAggregates] = useState({ active: 0, completed: 0, returned: 0 });
  const [loading, setLoading] = useState(false)
  const [allOrders, setAllOrders] = useState([]);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);

    const { phoneNumber, searchType, dateCreated } = data;
    const filters = []

    if (dateCreated) {
      const startDate = dateCreated.startOf('day').toDate();
      const endDate = dateCreated.endOf('day').toDate();
      filters.push(where('dateCreated', '>=', startDate));
      filters.push(where('dateCreated', '<=', endDate));
    }

    if (phoneNumber) {
      filters.push(where(searchType, '==', phoneNumber));
    }
    filters.push(orderBy('dateCreated', 'desc'))
    const queries = [
      query(orderRef, ...filters),
      query(completedOrdersRef, ...filters),
      query(returnedOrderRef, ...filters),
    ];
    const aggregateQueries = [
      query(orderRef, ...filters, where('redemption', '>=', 0)),
      query(completedOrdersRef, ...filters, where('redemption', '>=', 0)),
      query(returnedOrderRef, ...filters, where('redemption', '>=', 0)),
    ]

    try {
      const [
        activeCount,
        completedCount,
        returunedCount,
      ] = await Promise.all(
        aggregateQueries.map(q => getCountFromServer(q))
      );
      setAggregates({
        active: activeCount.data().count,
        completed: completedCount.data().count,
        returned: returunedCount.data().count,
      });
      const [activeOrders, completedOrders, returnedOrders] = await Promise.all(
        queries.map(q => getDocs(q))
      );
      const combinedOrders = [
        ...activeOrders.docs.map(doc => ({ id: doc.id, ...doc.data(), path: '' })),
        ...completedOrders.docs.map(doc => ({ id: doc.id, ...doc.data(), path: 'completed' })),
        ...returnedOrders.docs.map(doc => ({ id: doc.id, ...doc.data(), path: 'returned' })),
      ];
      setAllOrders(combinedOrders);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };

  const handleReset = () => {
    reset({
      phoneNumber: '+996',
      dateCreated: null,
      searchType: 'sender',
    });
    setAllOrders([]);
    setAggregates({
      active: 0,
      completed: 0,
      returned: 0,
    })
  };

  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const columns = AllOrderColumns({
    actionsConfig: actionsConfig,
    path: '/order/details',
    editable: false,
    customLabels: { ...statusLabels, ...returnedOrderStatusLabels },
    orderType: 'completed',
  });

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Все заказы' />
      <Title title='Все заказы' icon={<FindInPageRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <Stack direction='row' gap={1}>
          <form className="search-form allOrders" onSubmit={handleSubmit(onSubmit)}>
            <div className="search-input-block">
              <Stack direction='column' gap={2}>
                <Controller
                  name='phoneNumber'
                  defaultValue='+996'
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextField
                      label='Номер телефона'
                      size='small'
                      type="search"
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        const trimmedValue = value.trim();
                        setValue('phoneNumber', trimmedValue);
                      }}
                    />
                  )}
                />
                <Controller
                  name='dateCreated'
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <DatePicker
                      label="Дата"
                      value={field.value}
                      onChange={(date) => field.onChange(date)}
                      renderInput={(params) => <TextField {...params} size='small' />}
                    />
                  )}
                />
              </Stack>
              <Controller
                name='searchType'
                control={control}
                defaultValue='sender'
                render={({ field }) => (
                  <RadioGroup row {...field} sx={{
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                    <FormControlLabel
                      value="sender"
                      control={<Radio />}
                      label="Отправитель"
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      value="receiver"
                      control={<Radio />}
                      label="Получатель"
                      labelPlacement="top"
                    />
                  </RadioGroup>
                )}
              />
              <Stack direction='row' gap={1}>
                <Button type='submit' variant='black-contained' fullWidth>
                  Запрос
                </Button>
                <Tooltip title="Сбросить фильтры" placement='right' arrow>
                  <Button
                    variant='contained'
                    onClick={handleReset}
                    sx={{ width: '10%' }}
                    color='inherit'
                  >
                    <FilterAltOffRoundedIcon />
                  </Button>
                </Tooltip>
              </Stack>
            </div>
          </form>
          <Stack direction='row' gap={1} mt={2.5}>
            <BalanceCard
              title='Кол-во активных заказов'
              value={aggregates.active}
              icon={NumbersRoundedIcon}
              iconColor='#635BFF'
            />
            <BalanceCard
              title='Кол-во доставленных заказов'
              value={aggregates.completed}
              icon={NumbersRoundedIcon}
              iconColor='#10B981'
            />
            <BalanceCard
              title='Кол-во возвратных заказов'
              value={aggregates.returned}
              icon={NumbersRoundedIcon}
              iconColor='#FB9C0C'
            />
          </Stack>
        </Stack>
        <div className="orders-card-wrapper">
          <div className="orders-card-header">
            <div className='orders-card-parent__btns'>
              <ExportToExcelButtons ordersData={allOrders} />
            </div>
          </div>

          <OrderGrid
            orders={allOrders}
            columns={columns}
            size={20}
            loading={loading}
            usePagination={false}
          />
        </div>
      </div>
    </div>
  )
}

export default AllOrders