import axios from 'axios';
import { getAuthToken } from '../apps/Auth/Login/authToken';
import { handleSignOut } from '.';

const BASE_URL = process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const token = await getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        console.warn("Токен отсутствует");
      }
    } catch (error) {
      console.error("Ошибка при добавлении токена в заголовок:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await getAuthToken();
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (tokenError) {
        console.error("Ошибка при повторном запросе с обновленным токеном:", tokenError);
        handleSignOut();
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
