import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Stack,
} from '@mui/material';
import axiosInstance from '../../../../../configs/axiosInstance';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../../components/Header/Header';
import { Title } from '../../../../../components/Title/Title';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { userCollectionRef } from '../../../../../utils/collectionRefs';
import { addDoc } from 'firebase/firestore';

const AddSize = ({ pageTitle }) => {
  const [open, setOpen] = useState(false);
  const { handleSubmit, reset, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');

  const handleAddSize = async (data) => {
    setOpen(true);

    const historyData = {
      date: new Date(),
      description: `${userType} ${userName} добавил размер ${data.name}`
    }
    try {
      await axiosInstance.post('/category/size/', data);
      await addDoc(userCollectionRef, historyData)

      toast.success('Размер успешно добавлен', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      setOpen(false);
      reset();
      navigate('/sizes');
    } catch (e) {
      setOpen(false);
      toast.error('Произошла ошибка при добавлении размера', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Добавить размер' />
      <Title title='Добавить размер' icon={<AddCircleRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <div className="addCourier-wrapper kassa">
          <div className="order-block-head">
            <h3>добавить размер</h3>
          </div>
          <form className="add-courier-form" onSubmit={handleSubmit(handleAddSize)}>
            <Stack direction='column' gap={2}>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле!' }}
                render={({ field }) => (
                  <TextField
                    label='Название'
                    variant='outlined'
                    size='small'
                    helperText={errors?.name?.message}
                    error={errors?.name && true}
                    {...field}
                  />
                )}
              />
              <Controller
                name='desc'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    label='Описание'
                    variant='outlined'
                    size='small'
                    {...field}
                  />
                )}
              />
              <Button
                type='submit'
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
                sx={{ marginTop: '8px' }}
              >
                Добавить Размер
              </Button>
            </Stack>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddSize
