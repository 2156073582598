import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';

// Иконки для пользователей
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

// Иконки для финансов
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MoneyOffCsredOutlinedIcon from '@mui/icons-material/MoneyOffCsredOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

// Прочие
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';

import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import NoteAltOulinedIcon from '@mui/icons-material/NoteAltOutlined';

const userType = localStorage.getItem('userType');

export const mainLinks = [
  { path: '/', iconB: <AccountBoxOutlinedIcon />, title: 'Профиль' },
  { path: '/dashboard', iconB: <AssessmentIcon />, title: 'Статистика' },
  { path: '/search', iconB: <FindInPageOutlinedIcon />, title: 'Искать везде' },
  { path: '/allOrders', iconB: <FindInPageOutlinedIcon />, title: 'Заказы по дате' },
  ...(userType !== 'Бухгалтер'
    ? [{ path: '/order/add', iconB: <AddCircleIcon />, title: 'Создать заказ' }]
    : []),
];
export const orderLinks = [
  {
    iconB: <AssignmentOutlinedIcon />,
    title: 'Активные',
    path: '/orders',
  },
  {
    iconB: <AssignmentOutlinedIcon />,
    title: 'Активные 2',
    path: '/orderss',
  },
  {
    iconB: <AssignmentTurnedInOutlinedIcon />,
    title: 'Завершеные',
    path: '/order/completed',
  },
  {
    iconB: <AssignmentReturnOutlinedIcon />,
    title: 'Возвраты',
    path: '/returned-orders',
  },

  {
    iconB: <AssignmentLateOutlinedIcon />,
    title: 'Отмененные',
    path: '/order/cancelled',
  },
  {
    iconB: <NoteAltOulinedIcon />,
    title: 'Измененные',
    path: '/order/edited',
  },
  {
    iconB: <AutoDeleteOutlinedIcon />,
    title: 'Удаленные',
    path: '/order/deleted',
  },
  {
    iconB: <UpdateOutlinedIcon />,
    title: 'Долгие',
    path: '/longorders',
  },
]

export const accountingLinks = [
  {
    iconB: <CurrencyExchangeIcon />,
    title: 'Банк и касса',
    path: '/bank',
  },
  {
    iconB: <MoneyOffCsredOutlinedIcon />,
    title: 'Долги',
    path: '/order/debt',
  },
  {
    iconB: <RequestQuoteOutlinedIcon />,
    title: 'Счет фактура',
    path: '/schetFaktura',
  },
  {
    iconB: <MonetizationOnOutlinedIcon />,
    title: 'Транзакции',
    path: '/transaction',
  },
]
export const couriersLinks = [
  {
    iconB: <RecentActorsOutlinedIcon />,
    title: 'Список курьеров',
    path: '/couriers',
  },
  {
    iconB: <PersonAddAltOutlinedIcon />,
    title: 'Создать курьера',
    path: '/courier/add',
  },
  {
    iconB: <LocationOnOutlinedIcon />,
    title: 'Карта курьеров',
    path: '/courier/map',
  },
  {
    iconB: <PeopleAltOutlinedIcon />,
    title: 'Список агентов',
    path: '/agents',
  },
  {
    iconB: <AddCircleIcon />,
    title: 'Добавить агента',
    path: '/agent/add',
  },
]
export const marketplaceLinks = [
  {
    iconB: <CategoryOutlinedIcon />,
    title: 'Категории',
    path: '/categories',
  },
  {
    iconB: <CategoryOutlinedIcon />,
    title: 'Подкатегории',
    path: '/subCategories',
  },
  {
    iconB: <CategoryOutlinedIcon />,
    title: 'Подподкатегории',
    path: '/subSubCategories',
  },
  {
    iconB: <PaletteOutlinedIcon />,
    title: 'Цвета',
    path: '/colors',
  },
  {
    iconB: <SquareFootOutlinedIcon />,
    title: 'Размеры',
    path: '/sizes',
  },
  {
    iconB: <AdjustOutlinedIcon />,
    title: 'Бренды',
    path: '/brands',
  },
  {
    iconB: <StoreMallDirectoryOutlinedIcon />,
    title: 'Магазины',
    path: '/stores',
  },
]
export const otherLinks = [
  {
    iconB: <PublicOutlinedIcon />,
    title: 'Города',
    path: '/cities',
  },
  {
    iconB: <PublicOutlinedIcon />,
    title: 'Список заказов (Город)',
    path: '/cityOrders',
  },
  {
    iconB: <AddCircleIcon />,
    title: 'Создать заказ (Город)',
    path: '/cityOrder/add',
  },
  {
    iconB: <PeopleAltOutlinedIcon />,
    title: 'Список клиентов',
    path: '/clients',
  },
]