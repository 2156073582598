import React, { useCallback, useEffect, useState } from 'react'
import { Header } from '../../../../../components/Header/Header'
import { Helmet } from 'react-helmet'
import { Title } from '../../../../../components/Title/Title'
import { useFetchData } from '../../../../../hooks/useFetchData'
import { useNavigate } from 'react-router-dom'
import OrderGrid from '../../../../../components/OrderGrid '
import { Button, MenuItem, Stack, TextField } from '@mui/material'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { storeColumn } from '../constants/MarketPlaceColumn'
import axiosInstance from '../../../../../configs/axiosInstance'
import { debounce } from 'lodash'
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';

const Stores = ({ pageTitle }) => {
  const navigate = useNavigate()
  const { data: category } = useFetchData("/category/category/");

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchStores = async () => {
  //     setLoading(true);
  //     try {
  //       const params = {};
  //       if (categoryFilter) params.category = categoryFilter;
  //       if (searchTerm) params.search = searchTerm;

  //       const response = await axiosInstance.get("/category/store/", { params });
  //       setData(response.data?.results || []);
  //     } catch (err) {
  //       console.error("Ошибка загрузки брендов:", err);
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchStores();
  // }, [categoryFilter, searchTerm]);

  const fetchStores = async (categoryFilter, searchTerm) => {
    setLoading(true);
    try {
      const params = {};
      if (categoryFilter.length > 0) {
        params.categories = categoryFilter.join(','); // Массив категорий превращаем в строку
      }
      if (searchTerm) {
        params.search = searchTerm;
      }

      const response = await axiosInstance.get("/category/store/", { params });
      setData(response.data?.results || []);
    } catch (err) {
      console.error("Ошибка загрузки брендов:", err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchStores = useCallback(
    debounce((categoryFilter, searchTerm) => {
      fetchStores(categoryFilter, searchTerm);
    }, 500),
    []
  )
  useEffect(() => {
    debouncedFetchStores(categoryFilter, searchTerm);
    return () => {
      debouncedFetchStores.cancel();
    };
  }, [categoryFilter, searchTerm, debouncedFetchStores]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setCategoryFilter(value);
  };

  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Cтатистика' initial='Список магазинов' />
        <Title title={'Список магазинов'} icon={<StoreMallDirectoryOutlinedIcon fontSize='24' />} />
        <div className="container-inner">

          <div className='orders-sorting-header clear-btn'>
            {/* <Button variant='contained' color='inherit' onClick={clearFilter} sx={{ ml: 'auto' }}>
              <FilterAltOffRoundedIcon />
            </Button> */}
            <Stack
              sx={{ width: '100%', flexWrap: 'wrap' }}
              direction='row'
              gap={2}
            >
              <div className='order-sort'>
                <TextField
                  type='search'
                  label="Поиск"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='По категории'
                  fullWidth
                  size='small'
                  defaultValue=''
                  value={categoryFilter}
                  onChange={handleCategoryChange}
                  SelectProps={{
                    multiple: true,
                  }}
                >
                  {category?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>

              </div>

            </Stack>
          </div>
          {error ? <h1>Error :(</h1> : (
            <div className="orders-card-wrapper">
              <OrderGrid
                orders={data}
                columns={storeColumn}
                loading={loading}
                size={20}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Stores