import { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip as ChartTooltip } from 'recharts';

const DashboardChart = ({ tabIndex, orders, ordersDone, ordersReturned, ordersCancelled, ordersDeleted,dateFormatterWithoutYear }) => {
  const green = '#15B79F';
  const yellow = '#FB9C0C';
  const lavanda = '#635BFF';

  const chartConfig = [
    { index: 0, key: "Активные", color: lavanda, state: orders, dateKey:'dateCreated' },
    { index: 1, key: "Доставленные", color: green, state: ordersDone, dateKey: 'dateDelivered'},
    { index: 2, key: "Возвраты", color: yellow, state: ordersReturned, dateKey: 'dateCreated'},
    { index: 3, key: "Отмененные", color: 'red', state: ordersCancelled, dateKey: 'dateCreated'},
    { index: 4, key: "Удаленные", color: 'darkred', state: ordersDeleted, dateKey: 'dateCreated'},
  ];

  const chartData = useMemo(() => {
    return chartConfig.map(({ state, key, dateKey }) => {
      const combinedData = {};
      state?.forEach(order => {
        const date = dateFormatterWithoutYear(order[dateKey]);
        if (!combinedData[date]) combinedData[date] = { date };
        combinedData[date][key] = (combinedData[date][key] || 0) + 1;
      });
      return Object.values(combinedData);
    });
  }, [orders, ordersDone, ordersReturned, ordersCancelled, ordersDeleted,dateFormatterWithoutYear]);

  const getChartData = () => chartData[tabIndex] || [];

  return (
    <div className='dashboard-chart-container'>
      {getChartData().length > 0 && (
        <ResponsiveContainer width='100%' height={250}>
          <LineChart data={getChartData()} barCategoryGap='10%' stackOffset='none'>
            <CartesianGrid strokeDasharray="3 1" vertical={true} />
            <XAxis dataKey="date" tick={{ fill: '#000' }} axisLine={true} tickLine={true} />
            <YAxis tick={{ fill: '#000' }} axisLine={true} tickLine={true} />
            <ChartTooltip labelFormatter={(value) => value} contentStyle={{ borderRadius: '8px' }} />
            {chartConfig
              .filter(config => config.index === tabIndex)
              .map(config => (
                <Line
                  key={config.key}
                  type="monotone"
                  dataKey={config.key}
                  stroke={config.color}
                  fill={config.color}
                  fillOpacity={0.3}
                  strokeWidth={2}
                  activedot={{ r: 8 }}
                  dot={{ r: 3 }}
                  animationDuration={2000}
                  isAnimationActive
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default DashboardChart;
