import React, { useEffect, useState } from 'react';
import { doc, onSnapshot, deleteDoc, updateDoc, addDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Loader } from '../../../../components/Loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, } from '@mui/material';
import { Box } from '@mui/system';
import avatar from '../../../../assets/images/avatar.svg';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { agentsRef, userCollectionRef, } from '../../../../utils/collectionRefs';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';

const AgentDetail = ({ pageTitle }) => {
  const { id } = useParams();
  const [courier, setCourier] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [operation, setOperation] = useState('');
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const date = new Date()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const navigate = useNavigate();

  useEffect(() => {
    const getCourier = () => {
      const docRef = doc(agentsRef, id);

      const unsubscribe = onSnapshot(docRef, async (docSnap) => {
        if (docSnap.exists()) {
          const courierData = docSnap.data();

          setCourier({ ...courierData, id: docSnap.id });
        } else {
          console.log("Document doesn't exist");
        }
      });

      return () => {
        unsubscribe();
      };
    };

    getCourier();
  }, [id]);

  const handleDeleteCourier = async (courierId) => {
    try {
      await deleteDoc(doc(agentsRef, id));
      await addDoc(userCollectionRef, {
        date: date,
        description: `${userType} ${userName} удалил агента ${courier?.name} ${courier?.surName}`
      })
      toast.success('Агент успешно был удален', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      navigate('/agents')
    } catch (error) {
      toast.error('Произошла ошибка при удалении агента:', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      console.error('Error deleting courier:', error);
    }
  };

  const onSubmitTransaction = async (data) => {
    let loadingToastId;
    const newAmount = parseInt(data.amount) * (operation === 'subtract' ? -1 : 1);
    const newBalanceOfCourier = { balance: (courier?.balance || 0) + newAmount, }
    const description = `${userType} ${userName} обновил баланс агента из  ${courier?.balance}c -> ${newBalanceOfCourier.balance}c`
    try {
      loadingToastId = toast.loading('Изменение баланса', {
        autoClose: false,
      });

      await updateDoc(doc(agentsRef, id), newBalanceOfCourier);
      await addDoc(userCollectionRef, {
        date: date,
        description: description
      })
      toast.success('Баланс успешно обновлен');
      toast.dismiss(loadingToastId);

      handleClose();
      reset();
    } catch (error) {
      console.error('Error adding transaction: ', error);
    }
  };

  const handleOpen = (op) => {
    setOperation(op);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Список курьеров' initial='Детали агента' />
      <Title title='Детали агента' icon={<AssignmentIndRoundedIcon fontSize='large' />} />
      <div className='container-inner'>
        <div className='courier-more-wrapper'>
          {!courier ? (
            <Loader />
          ) : (
            <div className='courier-more-card'>
              <div className='courier-card-left-side'>
                <div className='courier-card-avatar'>
                  <img
                    src={!courier?.avatar ? avatar : courier?.avatar}
                    alt={courier?.name}
                  />
                  <span className={!courier?.online ? 'courier-card-status offline' : 'courier-card-status online'}
                  ></span>
                </div>
                <h3>
                  {courier?.name} {courier?.surName}
                </h3>
              </div>
              <div className='courier-card-right-side'>
                <div className='courier-card-list'>
                  <span>
                    <p>Имя:</p>
                    <p>{courier?.name}</p>
                  </span>
                  <span>
                    <p>Фамилия:</p>
                    <p>{courier?.surName}</p>
                  </span>
                  <span>
                    <p>Номер:</p>
                    <p>{courier?.phone}</p>
                  </span>
                </div>
                <div className='courier-card-list'>
                  <Box display='flex' gap='7px' className='courier-button-list'>
                    {userType === 'Админ' && (
                      <>
                        <Button
                          variant='black-contained'
                          onClick={() => navigate(`/agent/update/${courier?.id}`)}
                        >
                          <EditRoundedIcon />
                        </Button>
                        <Button
                          variant='black-contained'
                          onClick={() => setOpenDialog(true)}
                        >
                          <DeleteRoundedIcon />
                        </Button>
                      </>
                    )}
                    <Divider orientation='vertical' flexItem />
                  </Box>
                </div>
              </div>
            </div>

          )}
          <div className='courier-avatar-card'>
            <div className='courier-balance-card courier-balance-card__two'>
              <div className='courier-balance-card-title'>
                <MonetizationOnRoundedIcon style={{ color: '#10B981' }} />
                <p>Balance</p>
              </div>
              <div className='courier-balance-card-data'>
                <p>{(courier?.balance ?? 0).toLocaleString()}с</p>
              </div>
              <div className='courier-balance-btn-container'>
                <Button
                  variant='contained'
                  size='md'
                  className='courier-more-btn'
                  onClick={() => handleOpen('add')}
                  disabled={!courier}
                >
                  <AddRoundedIcon />
                </Button>
                <Button
                  variant='contained'
                  size='md'
                  className='courier-more-btn'
                  onClick={() => handleOpen('subtract')}
                  disabled={!courier}
                >
                  <RemoveRoundedIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        event={handleDeleteCourier}
        text={`Вы уверены, что хотите удалить агента? "${courier?.name} ${courier?.surName}"?`}
        btnText='Удалить'
      />
      <Dialog open={open} onClose={handleClose} maxWidth='xs'>
        <DialogTitle>Изменение баланса</DialogTitle>
        <form>
          <DialogContent>
            <Controller
              name='amount'
              control={control}
              defaultValue=''
              rules={{ required: true, min: 1 }}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin='dense'
                  id='amount'
                  label='Сумма'
                  type='number'
                  fullWidth
                  error={!!errors.amount}
                  {...field}
                />
              )}
            />
            <Controller
              name='comment'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  margin='dense'
                  id='comment'
                  label='Комментарий'
                  fullWidth
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: '#000b1f' }} variant='text'>
              Отмена
            </Button>
            <Button
              type='submit'
              variant='text'
              sx={{ color: '#000b1f' }}
              onClick={handleSubmit(onSubmitTransaction)}
            >
              {operation === 'subtract' ? 'Списать' : 'Добавить'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AgentDetail;
