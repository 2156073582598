import React, { useRef } from 'react'
import '../scss/Form.scss'
import { Button, Stack } from '@mui/material';
import { PrintRounded } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';

const Rko = () => {
  const rkoRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => rkoRef.current,
  });
  return (
    <Stack display='flex' direction='column' mt={2}>
      <div>
        <Button
          variant='black-contained'
          endIcon={<PrintRounded />}
          onClick={handlePrint}
        >
          печать
        </Button>
      </div>
      <div ref={rkoRef} className="pko-container rko">
        <div className="left rko">
          <div className="top-header">
            <div>
              <p>Унифицированная форма №КО-2</p>
              <p>Утверждена постановлением Нацстаткомитет</p>
              <p>Кыргызской Республики</p>
            </div>
          </div>
          <div className='section-1'>
            <table className='sec-1-left-table rko'>
              <thead>
                <tr>
                  <th className='rko'>ОсОО "Айла Экспресс"</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    организация
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Форма по ГКУД по ОКПО</p>
            <table className='sec-1-right-table'>
              <tbody>
                <tr>
                  <td>Код</td>
                </tr>
                <tr>
                  <td>0310002</td>
                </tr>
                <tr>
                  <td>32544049</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='section-2'>
            <table className='sec-2-table'>
              <tbody>
                <tr>
                  <td>подразделение</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section-3">
            <h2 className='rko'>Расходный кассовый ордер</h2>
            <table className='sec-3-table'>
              <tbody>
                <tr>
                  <td>Номер документа</td>
                  <td>Дата составления</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>31.08.2024</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section-4">
            <table className='sec-4-table rko'>
              <tbody>
                <tr className='sec-4-table-header'>
                  <th colSpan="3" className='debet'>Дебет</th>
                  <th className='credit'>Кредит</th>
                  <th>Сумма, KGS</th>
                  <th className='code'>Код целевого назначения</th>
                </tr>
                <tr className='sec-4-table-body'>
                  <th>Код структурного подразделения</th>
                  <th>Корреспондерующий счет, субсчет</th>
                  <th>Код аналитического учета</th>
                  <td rowspan="2">1110</td>
                  <td rowspan="2">8660.00</td>
                  <td rowspan="2"><br /></td>
                </tr>
                <tr className='thick-border'>
                  <td><br /></td>
                  <td>1520</td>
                  <td><br /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section-5">
            <table className='sec-5-table'>
              <tbody>
                <tr style={{ marginTop: '10px' }}>
                  <td rowSpan="2">Выдать:</td>
                  <td className="underline">
                    <input type="text" value="Мамыралиева Адиза Зьядатбековна" className='rko' />
                  </td>
                </tr>
                <tr>
                  <td className='note'>фамилия, имя , отчество</td>
                </tr>
                <tr>
                  <td>Основание:</td>
                  <td className="underline">
                    <input type="text" />
                  </td>
                </tr>
                <tr style={{ marginTop: '10px' }}>
                  <td rowSpan="2">Сумма:</td>
                  <td className="underline">
                    <input type="text" value="Восемь тысяч шестьсот шестьдесят сом 00 тыйын" className='rko' />
                  </td>
                </tr>
                <tr>
                  <td className='note'>прописью</td>
                </tr>
                <tr style={{ marginTop: '10px' }}>
                  <td>Приложение:</td>
                  <td className="underline">
                    <input type="text" />
                  </td>
                </tr>
                <tr style={{ marginTop: '10px' }}>
                  <td><strong>Рукодоводитель</strong></td>
                  <td className="underline">
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td className='note-flex'>
                    <p>должность</p>
                    <p>подпись</p>
                    <p>расшифровка подписи</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='section-6'>
            <div className="section-content">
              <div>
                <h4 className='rko'>Главный бухгалтер</h4>
                <span>
                  <strong> ______________________________________________________</strong>
                  <br />
                  <p>подпись</p>
                </span>
              </div>

              <div>
                <span>
                  <strong>______________________________________________________</strong>
                  <br />
                  <p>расшифровка подписи</p>
                </span>
              </div>
            </div>
            <div className='section-content rko'>
              <div>
                <p className='rko'>Получил:</p>
              </div>

              <div>
                <span>
                  <strong>______________________________________________________________________________________________________________________________</strong>
                </span>
              </div>
            </div>
            <div className='section-content rko'>
              <div>
                <span>
                  <strong>____________________________________________________________________________________________________________________________________________</strong>
                </span>
              </div>
            </div>
          </div>

          <div className='section-6'>
            <div className='section-content'>
              <div>
                <h4><u>31.08.2024</u></h4>
              </div>
              <div>
                <p className='rko'>Подпись <strong>__________________________________</strong></p>
              </div>
            </div>
          </div>
          <div className="section-5">
            <table className='sec-5-table'>
              <tbody>
                <tr style={{ marginTop: '10px' }}>
                  <td rowSpan="2">По:</td>
                  <td className="underline">
                    <input
                      type="text"
                      value="паспорт Серия ID №2097293 выдан МКК 211012 от 16 марта"
                      className='rko'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='note'>наименование, номер, дата и место выдачи документа</td>
                </tr>
                <tr>
                  <td className="underline">
                    <input type="text" />
                  </td>
                </tr>
                <tr>
                  <td className='note'>удостоверяющего личность получателя</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='section-6'>
            <div className='section-content'>
              <div>
                <h4>Выдал кассир</h4>
                <span>
                  <strong>_______________________________________________________</strong>
                  <br />
                  <p>подпись</p>
                </span>
              </div>

              <div>
                <span>
                  <strong>_______________________________________________________</strong>
                  <br />
                  <p>расшифровка подписи</p>
                </span>
              </div>
            </div>
          </div>
          {/* <div className="section-7">
            <div className='content-1'>
              <p>По</p>
              <h4>
                <u>
                </u>
                </h4>
            </div>
          </div> */}
        </div>
      </div>
    </Stack>
  )
}

export default Rko