import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';

export const storeDataItems = (data) => {
  return [
    [
      {
        icon: <StoreMallDirectoryRoundedIcon />,
        primary: 'Название',
        secondary: `${data?.name} | Автор: ${data?.author}`,
      },

      {
        icon: <CategoryRoundedIcon />,
        primary: 'Категории',
        secondary:
          data?.categories?.length > 0
            ? data.categories.map((cat) => cat.name).join(', ')
            : 'Нет категории',
      },

      {
        icon: <PublicOutlinedIcon />,
        primary: 'Город',
        secondary: data?.cityName || '---',
      },
      {
        icon: <InfoOutlinedIcon />,
        primary: 'О магазине',
        secondary: data?.about || '---',
      },
    ],

    [
      {
        icon: <LocationOnRoundedIcon />,
        primary: 'Адрес',
        secondary: (
          <a
            href={`https://2gis.kg/${data?.twoGis}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'block', wordWrap: 'break-word' }}
          >
            {data?.address}
          </a>
        ),
      },
      {
        icon: <LocalPhoneRoundedIcon />,
        primary: 'Телефон',
        secondary: (
          <a
            href={`tel:${data?.phone}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'block', wordWrap: 'break-word' }}
          >
            {data?.phone}
          </a>),
      },
      {
        icon: <FaInstagram />,
        primary: 'Instagram',
        secondary: (
          <a
            href={data?.instagramLink}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'block', wordWrap: 'break-word' }}
          >
            {data?.instagramLink}
          </a>
        ),
      },
      {
        icon: <FaWhatsapp />,
        primary: 'WhatsApp',
        secondary: (
          <a
            href={data?.whatsAppLink}
            target="_blank"
            rel="noreferrer"
            style={{ display: 'block', wordWrap: 'break-word' }}
          >
            {data?.whatsAppLink}
          </a>
        ),
      },
    ],
  ];
};