import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Title } from '../../../../components/Title/Title'
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { agentsRef } from '../../../../utils/collectionRefs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { doc, getDoc, updateDoc, } from "firebase/firestore";
import { MenuItem, TextField, Button, Backdrop, CircularProgress, Stack } from '@mui/material';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Helmet } from 'react-helmet';

const AgentEdit = ({ pageTitle }) => {
  const [courier, setCourier] = useState(null)
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    mode: "onChange",
  })

  useEffect(() => {
    const fetchCourier = async () => {
      try {
        const docRef = doc(agentsRef, `${id}`);
        const docSnap = await getDoc(docRef);
        const courierData = { ...docSnap.data(), id: docSnap.id };
        setCourier(courierData);
      } catch (e) {
        console.error('Error fetching courier data: ', e);
      }
    };
    fetchCourier();
  }, [id]);

  const pinValidationRules = {
    required: 'Обязательное полe!',
    validate: (value) => {
      if (value.length < 6) {
        return "ПИН должен быть больше 5 символов";
      }
      if (value.length > 6) {
        return "ПИН не должен быть больше 6 символов";
      }
    },
  };

  const handleEditCourier = async (data) => {
    try {
      setOpen(true);

      await updateDoc(doc(agentsRef, id), { ...data });
      setOpen(false);
      navigate('/agents');

      toast.success('Агент успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      reset();
    } catch (e) {
      console.error('Error updating document: ', e);
      setOpen(false);

      toast.error('Произошла ошибка при редактировании Агента', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className="container">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Cписок агентов'} initial={'Редактирование агента'} />
        <Title title={'Редактирование агента'} icon={<NoteAltRoundedIcon fontSize='large' />} />
        <div className="container-inner">
          {
            !courier
              ? <Loader />
              : (
                <div className="addCourier-wrapper city">
                  <div className='order-block-head'>
                    <h3>Редактирование агента</h3>
                  </div>
                  <form className='add-courier-form'>
                    <Stack className='add-courier-form__input'>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={courier?.name}
                        rules={{ required: 'Обьязательное полe!' }}
                        render={({ field }) => (
                          <TextField
                            label="Имя"
                            className="add-courier-input"
                            placeholder="Иван"
                            multiline
                            variant="outlined"
                            size="small"
                            helperText={errors?.name?.message}
                            error={errors?.name && true}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="surName"
                        control={control}
                        defaultValue={courier?.surName}
                        rules={{ required: 'Обьязательное полe!' }}
                        render={({ field }) => (
                          <TextField
                            className="add-courier-input"
                            label="Фамилия"
                            placeholder="Иванов"
                            variant="outlined"
                            size="small"
                            fullWidth
                            helperText={errors?.surName?.message}
                            error={errors?.surName && true}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="pin"
                        control={control}
                        defaultValue={courier?.pin}
                        rules={pinValidationRules}
                        render={({ field }) => (
                          <TextField
                            className="add-courier-input"
                            label="ПИН"
                            placeholder="0101"
                            variant="outlined"
                            sx={{ flex: 1 }}
                            fullWidth
                            size="small"
                            type='text'
                            inputProps={{ inputMode: "numeric" }}
                            helperText={errors?.pin?.message}
                            error={errors?.pin && true}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="type"
                        control={control}
                        defaultValue={courier?.active}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="filled-select-currency"
                            select
                            sx={{ width: '50%' }}
                            label="Активность"
                            variant="outlined"
                            className="add-courier-input"
                            size="small"
                          >
                            <MenuItem value={true}>
                              Активный
                            </MenuItem>
                            <MenuItem value={false}>
                              Не активный
                            </MenuItem>
                          </TextField>
                        )}
                      />
                    </Stack>
                    <Button
                      onClick={handleSubmit(data => handleEditCourier(data))}
                      size='large'
                      fullWidth
                      variant='contained'
                      style={{ background: '#000b1f' }}
                      sx={{ marginTop: "10px" }}
                    >
                      Сохранить
                    </Button>
                  </form>
                </div>
              )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  )
}

export default AgentEdit;