import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { BiPackage } from 'react-icons/bi';
import { GoPackage } from 'react-icons/go';
import { FiPackage } from 'react-icons/fi';
import { dateFormatter } from '../../../../utils/dateFormatter';
import { db } from '../../../../configs';
import { Title } from '../../../../components/Title/Title';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import {
  packageTypeLabels,
  paymentMethods,
} from '../../../../utils';
import {
  Divider,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
  DialogActions,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import MedicationRoundedIcon from '@mui/icons-material/MedicationRounded';
import FastfoodRoundedIcon from '@mui/icons-material/FastfoodRounded';
import OrderDetailBox from '../../../../components/OrderDetailBox';
import './scss/OrderDetail.scss';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import noImage from '../../../../assets/images/noimage.jpeg';
import { couriersRef, returnedOrderRef } from '../../../../utils/collectionRefs';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { returnedOrderStatus } from './constants';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const ReturnedOrderDetail = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [courierOne, setCourierOne] = useState(null);
  const [courierTwo, setCourierTwo] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [selectedChangeCourier, setSelectedChangeCourier] = useState(null);
  const userType = localStorage.getItem('userType');

  const qrCodeRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getOrder = async () => {
      try {
        const docRef = doc(returnedOrderRef, id);
        const data = await getDoc(docRef);
        return { ...data.data(), id: data.id };
      } catch (e) {
        console.error(e.message);
        return null;
      }
    };
    const getCouriers = async () => {
      try {
        const querySnapshot = await getDocs(couriersRef);
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        return data;
      } catch (error) {
        console.error(error.message);
        return null;
      }
    };
    getOrder().then((order) => setOrder(order));
    getCouriers().then((couriers) => setCouriers(couriers));
    // eslint-disable-next-line
  }, [id]);

  const fetchCourierData = async (courierId, setCourier) => {
    if (courierId) {
      const docRef = doc(db, 'couriers', courierId);
      const docSnap = await getDoc(docRef);
      setCourier(docSnap.exists() ? docSnap.data() : null);
    }
  };

  useEffect(() => {
    fetchCourierData(order?.courierOne, setCourierOne);
  }, [order?.courierOne]);

  useEffect(() => {
    fetchCourierData(order?.courierTwo, setCourierTwo);
  }, [order?.courierTwo]);


  const handleCourierChange2 = (_, value) => {
    setSelectedChangeCourier(value);
  };

  const updateCourier = async (orderRef, selectedCourier) => {
    return updateDoc(orderRef, {
      courierOne: selectedCourier,
      lastCourier: selectedCourier,
    });
  };

  const addToHistory = async (historyRef, description) => {
    return addDoc(historyRef, {
      date: new Date(),
      description: description,
    });
  };
  const changeCourier = async () => {
    try {
      const oldCourier = `${courierOne?.phone} (${courierOne?.name})`;
      const newCourier = `${selectedChangeCourier.id} (${selectedChangeCourier?.name})`;
      const description = `${userType} изменил курьера из "${oldCourier}" ➜ "${newCourier}"`;
      const LOADING_MESSAGE = 'Смена курьера...';
      const SUCCESS_MESSAGE = '"Курьер успешно заменен"';
      const orderRef = doc(returnedOrderRef, id);
      const historyRef = collection(orderRef, 'history');
      let loadingToastId = toast.loading(LOADING_MESSAGE, { autoClose: false });

      if (selectedChangeCourier) {
        await Promise.all([
          updateCourier(orderRef, selectedChangeCourier.id),
          addToHistory(historyRef, description),
        ]);
        toast.dismiss(loadingToastId);
        toast.success(`${SUCCESS_MESSAGE} ${order?.pid}`);
        setOpen2(false)
      }
    } catch (error) {
      const ERROR_MESSAGE = 'Ошибка при выборе смене курьера:';
      toast.error(ERROR_MESSAGE, {
        autoClose: 2000,
        hideProgressBar: false,
      });
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen2(false);
  };

  const imgList = [
    {
      urlPhoto: order?.senderItemPhoto,
      title: 'Фото отправителя',
    },
    {
      urlPhoto: order?.senderSignature,
      title: 'Подпись отправителя',
    },
    {
      urlPhoto: order?.receiverItemPhoto,
      title: 'Фото получателя',
    },
    {
      urlPhoto: order?.receiverSignature,
      title: 'Подпись получателя',
    },
  ];

  function renderOrderStatus(order) {
    const activeStepIndex = returnedOrderStatus.findIndex((status) => status.value === order.status);
    return (
      <Stepper
        activeStep={activeStepIndex !== -1 ? activeStepIndex : 10}
        alternativeLabel
        sx={{ overflowX: 'scroll', paddingBottom: '20px' }}
        className='scrollable'
      >
        {returnedOrderStatus.map((status, index) => (
          <Step key={status.value} completed={index <= activeStepIndex} sx={{ minWidth: '130px' }}>
            <StepLabel sx={{ fontSize: '12px', color: index < activeStepIndex ? 'inherit' : '#bdbdbd' }}>{status.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  const OrderDetails = ({ sender, receiver }) => {
    return (
      <>
        <OrderDetailBox
          title='Отправитель'
          items={[
            { label: 'Имя', value: sender?.senderName },
            { label: 'Номер телефона', value: sender?.sender },
            { label: 'Город', value: sender?.addressFrom?.cityName },
            {
              label: 'Регион/село',
              value: sender?.addressFrom?.districtName || '----------',
            },
            { label: 'Адрес отправки', value: sender?.addressFrom?.address },
          ]}
        />
        <OrderDetailBox
          title='Получатель'
          items={[
            { label: 'Имя', value: receiver?.receiverName },
            { label: 'Номер телефона', value: receiver?.receiver },
            { label: 'Город', value: receiver?.addressTo?.cityName },
            {
              label: 'Регион/село',
              value: receiver?.addressTo?.districtName || '----------',
            },
            { label: 'Адрес отправки', value: receiver?.addressTo?.address },
          ]}
        />
      </>
    );
  };

  const renderCourierDetailBox = (courier, title) => {
    if (!courier) return null;

    const items = [
      { label: 'Имя', value: `${courier.name} ${courier.surName}` },
      { label: 'Номер телефона', value: courier.phone },
      { label: 'Город', value: courier.cityInfo?.name },
      { label: 'Рейтинг', value: courier.raiting },
      { label: 'Тип курьера', value: courier.type },
    ];

    return <OrderDetailBox title={title} items={items} orderStatus={order?.status} setOpen2={setOpen2} />;
  };

  const renderPackageTypeIcon = (packageType) => {
    switch (packageType) {
      case 'document':
        return (
          <FilePresentRoundedIcon fontSize='small' sx={{ color: '#3A46D6' }} />
        );
      case 'medicine':
        return (
          <MedicationRoundedIcon fontSize='small' sx={{ color: '#b42318' }} />
        );
      case 'food':
        return (
          <FastfoodRoundedIcon fontSize='small' sx={{ color: '#f4450f' }} />
        );
      case 'large_box':
        return <GoPackage size={18} color='#f0ad4e' />;
      case 'small_box':
        return <BiPackage size={18} color='#f0ad4e' />;
      case 'box':
        return <FiPackage size={18} color='#f0ad4e' />;
      default:
        return null;
    }
  };

  const printOrderInfo = () => {
    if (qrCodeRef.current) {
      // Получите значение QR-кода и отправьте его на сервер для печати
      const qrCodeValue = qrCodeRef.current.toDataURL();
      // Ваш код для отправки qrCodeValue на сервер и печати
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Список заказов'} initial='Детали заказа' />
        <Title
          title={'Детали заказа'}
          icon={<DescriptionRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          {!order ? (
            <Loader />
          ) : (
            <div className='orders-more-wrapper'>
              <div className='orders-more-box'>
                <div className='orders-more-box-header'>
                  <h3>Статус заказа</h3>
                </div>
                <Divider />
                <Box sx={{ width: '100%' }}>{renderOrderStatus(order)}</Box>
              </div>

              <ImageList
                sx={{ width: 500, height: 450 }}
                cols={2}
                rowHeight={164}
              >
                {imgList.map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      srcSet={!item.urlPhoto ? noImage : item.urlPhoto}
                      src={!item.urlPhoto ? noImage : item.urlPhoto}
                      alt={item.title}
                      loading='lazy'
                    />
                    <ImageListItemBar title={item.title} position='top' />
                  </ImageListItem>
                ))}
              </ImageList>

              <div className='order-detail-wrap'>
                {[courierOne, courierTwo].map((courier, index) => (
                  <React.Fragment key={index}>
                    {renderCourierDetailBox(courier, `Курьер ${index + 1}`)}
                  </React.Fragment>
                ))}
              </div>

              <OrderDetails title='Заказ' sender={order} receiver={order} />

              <div className='order-detail-wrap'>
                <div className={'orders-more-box'}>
                  <div className='orders-more-box-header'>
                    <h3>Детали заказа</h3>
                  </div>
                  <Divider />
                  <ul className='orders-more-body-list'>
                    <li>
                      <span>ID</span>
                      <span>{order?.id}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Дата</span>
                      <span>{dateFormatter(order?.dateCreated?.seconds)}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Тип посылки</span>
                      <span className='order-more-list-type'>
                        {packageTypeLabels[order?.packageType]}
                        {renderPackageTypeIcon(order?.packageType)}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Тариф заказа</span>
                      <span className='order-more-list-type'>
                        {order?.tariff?.name}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Платежное лицо</span>
                      <span>
                        {(() => {
                          switch (order?.whoPays) {
                            case 1:
                              return 'Отправитель';
                            case 2:
                              return 'Получатель';
                            default:
                              return 'Совместно';
                          }
                        })()}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Метод оплаты</span>
                      <span>{paymentMethods[order?.paymentMethod]}</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Статус оплаты</span>
                      <span className='order-more-list-type'>
                        {!order?.paymentStatus ? 'Не оплачен' : 'Оплачен'}
                        {!order?.paymentStatus ? (
                          <DoNotDisturbAltRoundedIcon
                            fontSize='small'
                            sx={{ color: '#b42318' }}
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            fontSize='small'
                            sx={{ color: '#0b815a' }}
                          />
                        )}
                      </span>
                    </li>
                    <Divider />
                    <li>
                      <span>Выкуп</span>
                      <span>{order?.redemption}с</span>
                    </li>
                    <Divider />
                    <li>
                      <span>Сумма</span>
                      <span>{order?.cost}с</span>
                    </li>
                    <Divider />
                    <li className='order-more-list-comments'>
                      <span>Коментарии</span>
                      <span>
                        {order?.comments ? order?.comments : '----------'}
                      </span>
                    </li>
                    {
                      userType === 'Бухгалтер' && (
                        <>
                          <Divider />
                          <li className='order-more-list-comments'>
                            <span>Проверено</span>
                            <span>
                              {!order?.accChecked
                                ? <CancelRoundedIcon color='error' />
                                : <CheckBoxRoundedIcon color='success' />}
                            </span>
                          </li>
                        </>
                      )
                    }

                    <Divider />
                    <li className='order-more-list-comments'>
                      <span>Причина возврата</span>
                      <span>
                        {order?.rejectionReason
                          ? order?.rejectionReason
                          : '----------'}
                      </span>
                    </li>
                  </ul>
                </div>
                <Box
                  display='flex'
                  alignItems='center'
                  flexDirection='column'
                  gap='12px'
                // width={'50%'}
                // height={'200px'}
                >
                  <Box ref={qrCodeRef} className='order-qr-box'>
                    <div className='orders-more-box-header'>
                      <h3>PID: {order?.pid ? order?.pid : 'Нет PID'}</h3>
                    </div>
                    <QRCode value={order?.pid ?? 'Отсутствует'} size={260} />
                  </Box>
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        variant='contained'
                        sx={{ background: '#000b1f' }}
                        onClick={printOrderInfo}
                        fullWidth
                      >
                        Распечатать
                      </Button>
                    )}
                    content={() => qrCodeRef.current}
                  />
                </Box>
              </div>
              <div
                className='orders-more-box actions'
                style={{ maxWidth: '320px' }}
              >
                <Button
                  variant='contained'
                  onClick={() => navigate(`/order/history/orders/${id}`)}
                >
                  История
                </Button>
              </div>
            </div>
          )}
          <Dialog open={open2} onClose={handleClose} className='returned-order'>
            <DialogTitle>Сменить курьера</DialogTitle>
            <form style={{ width: '400px', height: '100%', display: 'flex', flexDirection: 'column' }}>

              <DialogContent>
                <Autocomplete
                  id='select-courier'
                  disablePortal
                  defaultValue={courierOne}
                  options={couriers || []}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.phone})`
                  }
                  onChange={handleCourierChange2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={`random-${Math.random().toString(36).substring(7)}`}
                      label='Выберите курьера'
                      variant='outlined'
                      size='small'
                    />
                  )}
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} sx={{ color: '#000b1f' }}>
                  Отмена
                </Button>
                <Button
                  onClick={changeCourier}
                  color='info'
                  disabled={!selectedChangeCourier}
                >
                  Сменить
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default ReturnedOrderDetail;
