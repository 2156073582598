import React, { useEffect, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { addDoc, collection, doc, getDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../../../../configs'
import { Loader } from '../../../../components/Loader/Loader'
import avatar from '../../../../assets/images/avatar.svg';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { toast } from 'react-toastify'

const Profile = ({ pageTitle }) => {
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  const USERTYPE = localStorage.getItem('userType');
  const USERNAME = localStorage.getItem('userName');
  const ROLETYPE = localStorage.getItem('roleType');

  const [openModal, setOpenModal] = useState(false);
  const [reviewText, setReviewText] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    const getUser = async () => {
      try {
        const docRef = doc(db, ROLETYPE, USERNAME);
        const data = await getDoc(docRef);
        return { ...data.data(), id: data.id };
      } catch (error) {
        console.error(error.message);
        return null;
      }
    };

    const fetchData = async () => {
      try {
        const user = await getUser();
        setUser(user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

  }, [ROLETYPE, USERNAME]);

  const submitReview = async (text) => {
    try {
      await addDoc(collection(db, "webReviews"), {
        text,
        userName: USERNAME,
        roleType: ROLETYPE,
        dateCreated: serverTimestamp(),
      });
      toast.success("Спасибо за ваш отзыв!");
    } catch (error) {
      console.error("Ошибка при отправке отзыва: ", error);
      toast.error("Произошла ошибка, попробуйте позже.");

    }
  };


  const handleSubmitReview = () => {
    submitReview(reviewText);
    handleCloseModal(); // Закрытие модалки после отправки
    setReviewText(""); // Очистка текста отзыва
  };

  return (
    <>
      <div className="container">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Профиль' />
        <div className="container-inner">
          <div className='courier-more-wrapper'>
            <div className='courier-more-card profile'>
              {loading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <div className='courier-card-left-side'>
                    <div className='courier-card-avatar'>
                      <img
                        src={!user?.avatar ? avatar : user?.avatar}
                        alt={user?.name}
                      />
                    </div>
                    <h3>
                      {' '}
                      {user?.login} {user?.name}
                    </h3>
                  </div>
                  <div className='courier-card-right-side'>
                    <div className='courier-card-list'>
                      <span>
                        <p>Тип аккаунта:</p>
                        <p>{USERTYPE}</p>
                      </span>
                      <span>
                        <p>Имя:</p>
                        <p>{user?.name}</p>
                      </span>
                      <span>
                        <p>Телефон:</p>
                        <p>{user?.phone}</p>
                      </span>

                    </div>
                    <div className='courier-card-list client'>
                      <span>
                        <p>Логин:</p>
                        <p>{user?.login}</p>
                      </span>
                      <span
                        className={
                          !user?.active ? 'blackList' : 'notBlackList'
                        }
                      >
                        <p>Статус:</p>
                        <p>
                          {!user?.active
                            ? 'Не активный !'
                            : 'Активный'}
                        </p>
                      </span>
                    </div>

                    <div className='courier-card-list profile'>
                      <Box display='flex' gap={'4px'}>

                        <Button
                          variant='contained'
                          className='courier-more-btn'
                          onClick={() => navigate(`/userHistory/${ROLETYPE}/${user?.id}`)}
                        >
                          История
                        </Button>
                      </Box>
                    </div>
                  </div>
                </React.Fragment>
              )}

            </div>
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 3,
              maxWidth: 800,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Card sx={{ width: '100%', textAlign: 'center', border: '5.5px dotted #3A46D6' }}>
              <CardContent>
                <Typography variant="h5" component="h2" sx={{ marginBottom: 2 }}>
                  Мы ценим ваше мнение!
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  Ваше мнение помогает нам становиться лучше! Поделитесь, что вам понравилось на нашем сайте, а что, по вашему мнению, можно улучшить. Мы готовы выслушать любые предложения, замечания и идеи.❤️
                  <br />
                  <br />
                  <hr />
                  <br />
                  Расскажите, что вы думаете о работе сайта, удобстве использования или, может быть, о функциях, которых вам не хватает. Мы внимательно рассмотрим каждый отзыв, чтобы сделать ваш опыт еще лучше.😊
                </Typography>
                <Button variant="black-contained" color="primary" onClick={handleOpenModal}>
                  Оставить отзыв
                </Button>
              </CardContent>
            </Card>
          </Box> 
        </div>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Оставьте свой отзыв</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Мы рады, что вы с нами! Поделитесь своим мнением о сайте.
          </Typography>
          <TextField
            label="Ваш отзыв"
            fullWidth
            multiline
            rows={4}
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Отмена
          </Button>
          <Button onClick={handleSubmitReview} color="primary">
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Profile