import React, { useCallback, useEffect, useState } from 'react'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { citiesRef, completedOrdersRef, orderRef } from '../../../../utils/collectionRefs';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import OrderGrid from '../../../../components/OrderGrid ';
import '../Couriers/scss/CourierDetail.scss';
import { Helmet } from 'react-helmet';
import { ExportCitiesToExcellButton } from '../../../../components/Buttons/ExportCitiesToExcell';
import { Loader } from '../../../../components/Loader/Loader';
import { fetchData } from './helpers/fetchData';
import { getCountFromServer, query, where } from 'firebase/firestore';
import { Tab, Tabs } from '@mui/material';
import { citiesColumn } from './helpers/CitiesColumns';

const fetchOrdersCountByDistrict = async (districts, tabIndex) => {
  const selectedRef = tabIndex === 0 ? orderRef : completedOrdersRef;
  
  const promises = districts.map(async (district) => {
    if (!district.id) {
      return {
        ...district,
        orderCount: 0,
      };
    }
    const countQuery = query(
      selectedRef,
      where("addressTo.city", "!=", null),
      where("addressTo.city", "==", Number(district.id))
    );

    try {
      const countSnapshot = await getCountFromServer(countQuery);
      const count = countSnapshot.data().count || 0;

      return {
        ...district,
        orderCount: count,
      };
    } catch (error) {
      console.error(`Ошибка при подсчете заказов для региона ${district.id}:`, error);
      return {
        ...district,
        orderCount: 0,
      };
    }
  });

  return Promise.all(promises);
};

const Cities = ({ pageTitle }) => {
  const [districts, setDistricts] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const loadInitialData = useCallback(async () => {
    setLoading(true);
    const data = await fetchData(citiesRef, 'name', 'asc');
    const districtsWithCounts = await fetchOrdersCountByDistrict(data, tabIndex);
    setDistricts(districtsWithCounts);
    setLoading(false);
  }, [tabIndex]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };
  const handleTabChange = useCallback((event, newIndex) => {
    setTabIndex(newIndex);
  }, []);

  const filteredData = districts.filter((order) =>
    ['name', 'id'].some((field) => {
      const value = order[field];
      return value && value.toLowerCase().includes(search.toLowerCase());
    })
  );

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Города' />
        <Title
          title={'Города'}
          icon={<PublicRoundedIcon fontSize='24' />}
        />
        <div className="container-inner">
          <div className="orders-sorting-header">
            <SearchInput
              label='Поиск по городу'
              placeholder='Введите'
              variant='outlined'
              onChange={handleSearchOrder}
            />
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  <ExportCitiesToExcellButton ordersData={districts} fileName='Список городов' />
                  <div>
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      scrollButtons='auto'
                      variant='scrollable'
                    >
                      <Tab label={`Активные`} />
                      <Tab label={`Завершенные`} />
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={filteredData}
                columns={citiesColumn(tabIndex)}
                size={20}
              />}

          </div>
        </div>
      </div>
    </>
  )
}

export default Cities