import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { AuthProvider } from './provider/AuthProvider';
import './index.scss'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import { theme } from './utils/theme';
import { InstantSearch } from 'react-instantsearch';
import { searchClient } from './configs';
import { SidebarProvider } from './provider/SidebarProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <SidebarProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InstantSearch
                searchClient={searchClient}
                indexName='Active order'
                insights={true}
                future={{
                  preserveSharedStateOnUnmount: true
                }}
              >
                <App />
              </InstantSearch>
            </LocalizationProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </SidebarProvider>
  </React.StrictMode>
);