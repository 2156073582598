import { useState, useEffect } from "react";
import axiosInstance from './../configs/axiosInstance';

export const useFetchData = (endpoint, id = null) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = id ? `${endpoint}/${id}` : endpoint;
        const response = await axiosInstance.get(url);
        setData(response.data || []);
        setCount(response?.data?.count || 0);
      } catch (err) {
        console.error("Ошибка загрузки данных:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint]);

  return { data, loading, error, count };
};
