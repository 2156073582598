import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import OrderGrid from '../../../../components/OrderGrid ';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { kassaColumn } from './constants/getKassaColumn';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import { useFetchData } from '../../../../hooks/useFetchData';

const KassaCategoryDetail = ({ pageTitle }) => {
  const navigate = useNavigate()
  const { kassaID } = useParams()
  const USERTYPE = localStorage.getItem('userType');

  const { data: kassa, loading, error, count } = useFetchData(`/core/kassa/?category=${kassaID}`);
  const columns = kassaColumn()
  const totalBalance = kassa?.results?.reduce((acc, item) => acc + (item.balance || 0), 0);

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Список касс' />
      <Title
        title='Список касс'
        icon={<CurrencyExchangeIcon fontSize='meduim' />}
      />
      <div className="container-inner">
        <div className='order-statistics-container'>
          <BalanceCard
            title='Общая сумма касс'
            value={totalBalance}
            icon={NumbersRoundedIcon}
            iconColor='#10B981'
          />
          <BalanceCard
            title='Общее количество касс'
            value={count}
            icon={NumbersRoundedIcon}
            iconColor='#635BFF'
          />

          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>

        <div className='orders-card-wrapper'>
          <div className='orders-card-header'>
            <div className='orders-card-parent'>
              <div className='orders-card-parent__btns'>
                {USERTYPE === 'Админ' && (
                  <Button variant='black-contained' onClick={() => navigate('/kassa/add')}>
                    Новая касса +
                  </Button>
                )}
              </div>
            </div>
          </div>

          {error ? <h1>Ошибка :(</h1> : (
            <OrderGrid
              orders={kassa.results}
              columns={columns}
              loading={loading}
              size={20}
              usePagination={false}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default KassaCategoryDetail