import React, { useEffect, useState } from 'react';
import { FormValidation } from '../../../../components/Form/FormValidation/exports';
import { citiesRef, cityOrdersRef, tariffRef } from '../../../../utils/collectionRefs';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { db } from '../../../../configs';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { typeOfOrder, paymentPerson, paymentStatus, payment } from '../../../../utils';
import { toast } from 'react-toastify';
import { TextField, Box, MenuItem, Backdrop, CircularProgress, Autocomplete, Button } from '@mui/material';
import { doc, getDoc, updateDoc, onSnapshot, addDoc, collection } from 'firebase/firestore';
import { Helmet } from 'react-helmet';

const EditCityOrder = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [city, setCity] = useState(null);
  const [open, setOpen] = useState(false);
  const [tariff, setTariff] = useState(null);
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');

  const userCollectionRef = collection(
    db,
    `${roleType}`,
    `${userName}`,
    'history'
  );

  useEffect(() => {
    const fetchOrder = async () => {
      const docRef = doc(cityOrdersRef, `${id}`);
      const docSnap = await getDoc(docRef);
      setOrder({ ...docSnap.data(), id: docSnap.id });
    };
    fetchOrder();
  }, [id]);

  useEffect(() => {
    const settingTariff = onSnapshot(tariffRef, (snapshot) => {
      setTariff(snapshot.docs.map((doc) => ({ ...doc.data() })));
    });
    return () => settingTariff();
  }, []);

  useEffect(() => {
    const settingCity = onSnapshot(citiesRef, (snapshot) => {
      const cityData = snapshot.docs.map((doc) => ({ ...doc.data() }));
      const sortedCityData = cityData.sort((a, b) => a.id - b.id);
      setCity(sortedCityData);
    });
    return () => settingCity();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const paymentMethodOptions = {
    cash: payment[0].value,
    mbank: payment[1].value,
    optima: payment[2].value,
    elsom: payment[3].value,
    odengi: payment[4].value,
    other: payment[5].value,
  };

  const handleUpdateOrder = async (editOrder) => {

    try {
      setOpen(true);
      const fromAddressData = {
        address: editOrder.fromAddress,
        city: 1,
        cityName: 'Бишкек',
        district: editOrder?.fromDistrict?.id ?? null,
        districtName: editOrder?.fromDistrict?.name ?? null,
        link2gis: editOrder?.fromLink2gis ?? '',
        lat: 42.876254,
        lon: 74.604228,
      };

      const toAddressData = {
        address: editOrder.toAddress,
        city: 1,
        cityName: 'Бишкек',
        district: editOrder?.toDistrict?.id ?? null,
        districtName: editOrder?.toDistrict?.name ?? null,
        link2gis: editOrder?.toLink2gis ?? '',
        lat: 42.876254,
        lon: 74.604228,
      };

      const updatedData = {
        addressFrom: fromAddressData,
        addressTo: toAddressData,
        tariff: {
          cost: editOrder.tariff.cost,
          name: editOrder.tariff.name,
          uid: String(editOrder.tariff.uid),
        },
        tariffId: String(editOrder.tariff.uid),
        comments: editOrder.commits,
        cost: Number(editOrder.cost) ?? Number(editOrder.tariff.cost),
        cityFilter: 1,
        cityFrom: 1,
        cityTo: 1,
        packageType: editOrder.packageType,
        paymentMethod: editOrder.paymentMethod,
        paymentStatus: editOrder.paymentStatus === 'false' ? false : true,
        receiver: editOrder.toPhone,
        receiverName: editOrder.toName,
        receiverPhone: editOrder.toPhone,
        redemption: Number(editOrder.redemption),
        sender: editOrder.fromPhone,
        senderName: editOrder.fromName,
        senderPhone: editOrder.fromPhone,
        whoPays: Number(editOrder.paymentPerson),
      };
      console.log(updatedData)
      await updateDoc(doc(cityOrdersRef, id), updatedData);

      await addDoc(collection(cityOrdersRef, id, 'history'), {
        date: new Date(),
        description: `${userType} ${userName} отредактировал детали заказа`,
      });

      await addDoc(userCollectionRef, {
        date: new Date(),
        description: `${userType} ${userName} отредактировал заказ с ID ${id}`
      })
      setOpen(false);
      navigate('/cityOrders');

      toast.success('Заказ успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      reset();
    } catch (e) {
      setOpen(false);
      console.error(e.message);
      toast.error('Произошла ошибка при редактировании заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Список заказов'} initial='Редактирование ' />
        <Title
          title={'Редактирование заказа'}
          icon={<NoteAltRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          {!city ? (
            <Loader />
          ) : (
            <form className='order-form'>
              <div className='order-form-flex'>
                {/* ОТПРАВИТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Отправитель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='fromPhone'
                      control={control}
                      defaultValue={order?.senderPhone}
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Отправитель'
                          size='small'
                          error={!!errors?.fromPhone}
                          helperText={errors?.fromPhone?.message}
                          {...field}
                        />
                      )}
                    />
                  
                    <TextField
                      id='outlined-basic'
                      label={'Имя отправителя'}
                      variant='outlined'
                      helperText={errors?.fromName?.message}
                      defaultValue={order?.senderName}
                      error={errors?.fromName && true}
                      size='small'
                      {...register('fromName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <Controller
                      name='fromDistrict'
                      control={control}
                      defaultValue={{
                        name: order?.addressFrom?.districtName,
                        id: order?.addressFrom?.district,
                      }}
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-district'
                          disablePortal
                          options={[]}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            field.onChange(val);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Район/село'
                              variant='outlined'
                              size='small'
                              error={!!errors?.fromDistrict}
                              helperText={errors?.fromDistrict?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Ссылка 2GIS'
                      variant='outlined'
                      size='small'
                      error={errors?.fromLink2gis ? true : false}
                      helperText={errors ? errors?.fromLink2gis?.message : ''}
                      defaultValue={order?.addressFrom?.link2gis}
                      {...register('fromLink2gis', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес отправителя'
                      variant='outlined'
                      size='small'
                      error={errors?.fromAddress ? true : false}
                      helperText={errors ? errors?.fromAddress?.message : ''}
                      defaultValue={order?.addressFrom?.address}
                      {...register('fromAddress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ----------------- */}
                {/* ПОЛУЧАТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Получатель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='toPhone'
                      control={control}
                      defaultValue={order?.receiverPhone}
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Получатель'
                          size='small'
                          error={!!errors?.toPhone}
                          helperText={errors?.toPhone?.message}
                          {...field}
                       
                        />
                      )}
                    />
                 
                    <TextField
                      id='outlined-basic'
                      label={'Имя получателя'}
                      variant='outlined'
                      size='small'
                      helperText={errors?.toName?.message}
                      defaultValue={order?.receiverName}
                      error={errors?.toName && true}
                      {...register('toName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <Controller
                      name='toDistrict'
                      control={control}
                      defaultValue={{
                        name: order?.addressTo?.districtName,
                        id: order?.addressTo?.district,
                      }}
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-district'
                          disablePortal
                          options={[]}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            field.onChange(val);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Район/село'
                              variant='outlined'
                              size='small'
                              error={!!errors?.toDistrict}
                              helperText={errors?.toDistrict?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Ссылка 2GIS'
                      variant='outlined'
                      size='small'
                      error={errors?.toLink2gis ? true : false}
                      helperText={errors ? errors?.toLink2gis?.message : ''}
                      defaultValue={order?.addressTo?.link2gis}
                      {...register('toLink2gis', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес получателя'
                      variant='outlined'
                      size='small'
                      error={errors?.toAddress && true}
                      helperText={errors?.toAddress?.message}
                      defaultValue={order?.addressTo?.address}
                      {...register('toAddress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ------------- */}
                {/* ДЕТАЛИ ЗАКАЗА */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Детали заказа</h3>
                  </div>
                  <div className='order-input-block'>
                    <Box className='' sx={{ display: 'flex', gap: '4px' }}>
                      <TextField
                        sx={{ width: '90%' }}
                        id='filled-select-currency'
                        select
                        label='Тип посылки'
                        defaultValue={order?.packageType}
                        variant='outlined'
                        size='small'
                        error={errors?.orderType && true}
                        {...register('packageType', {
                          required: FormValidation.RequiredInput.required,
                        })}
                      >
                        {typeOfOrder.map((type) => (
                          <MenuItem key={type.id} value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Controller
                        name='tariff'
                        control={control}
                        defaultValue={order?.tariff}
                        rules={{ required: 'Обязательное поле!' }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={tariff || []}
                            getOptionLabel={(option) =>
                              `${option?.name}(${option?.cost}с)` || ''
                            }
                            style={{ width: '90%' }}
                            onChange={(_, val) => {
                              field.onChange(val);
                              setValue('cost', val?.cost);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Тариф'
                                variant='outlined'
                                className='add-courier-input'
                                size='small'
                                style={{ width: '100%' }}
                                error={!!errors.tariff}
                                helperText={errors?.tariff?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <TextField
                      type='number'
                      size='small'
                      label='Стоимость доставки'
                      defaultValue={order?.cost ?? getValues('cost')}
                      {...register('cost')}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Выкуп (0 если без выкупа)'
                      variant='outlined'
                      defaultValue={order?.redemption}
                      type='number'
                      size='small'
                      {...register('redemption', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Кто оплачивает'
                      defaultValue={(() => {
                        switch (order?.whoPays) {
                          case 1:
                            return paymentPerson[0].value;
                          case 2:
                            return paymentPerson[1].value;
                          default:
                            return paymentPerson[2].value;
                        }
                      })()}
                      variant='outlined'
                      size='small'
                      {...register('paymentPerson', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentPerson.map((person) => (
                        <MenuItem key={person.id} value={person.value}>
                          {person.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Метод оплаты'
                      variant='outlined'
                      size='small'
                      defaultValue={paymentMethodOptions[order?.paymentMethod]}
                      {...register('paymentMethod', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {payment.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Статус оплаты'
                      defaultValue={`${order?.paymentStatus}`}
                      variant='outlined'
                      size='small'
                      {...register('paymentStatus', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentStatus.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                {/* ДОПОЛНИТЕЛЬНО */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Дополнительно</h3>
                  </div>
                  <div className='order-input-block'>
                    <TextField
                      id='outlined-multiline-static'
                      label='Коментарии'
                      multiline
                      rows={4}
                      defaultValue={order?.comments}
                      {...register('commits')}
                    />
                  </div>
                </div>
              </div>
              <Button
                onClick={handleSubmit((data) => handleUpdateOrder(data))}
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
              >
                Сохранить
              </Button>
            </form>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

export default EditCityOrder;
