import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../../components/Header/Header'
import { Title } from '../../../../../components/Title/Title'
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader/Loader';
import './scss/StoreDetail.scss'
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { storeDataItems } from '../constants/storeDataItems';
import OrderGrid from '../../../../../components/OrderGrid ';
import { ExportToExcelButtons } from '../../Search/helpers/ExportExcel';
import axiosInstance from '../../../../../configs/axiosInstance';
import { storeItemsColumn, storeOrderColumn } from '../constants/MarketPlaceColumn';

const renderList = (items) =>
  items.map((item, idx) => (
    <ListItem key={idx}>
      <ListItemAvatar>
        <Avatar>{item.icon}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={item.primary} secondary={item.secondary} />
    </ListItem>
  ));

const StoreDetail = ({ pageTitle }) => {
  const { id } = useParams()
  const { data, loading } = useFetchData('category/store', `${id}/`)

  const [tabIndex, setTabIndex] = useState(0);
  const [dataLoading, setDataLoading] = useState(false)
  const [error, setError] = useState(false)
  const [allData, setAllData] = useState(null)

  const fetchOrdersAndItems = useCallback(async () => {
    setDataLoading(true);
    try {
      const endpoint = tabIndex === 0 ? '/core/modelOrder/' : '/core/item/';
      const response = await axiosInstance.get(endpoint, {
        params: { store: id },
      });
      setAllData(response.data?.results || []);
    } catch (err) {
      console.error('Error loading data:', err);
      setError(err);
    } finally {
      setDataLoading(false);
    }
  }, [id, tabIndex]);

  const handleTabChange = useCallback((event, newIndex) => {
    setTabIndex(newIndex);
  }, []);

  const storeItems = storeDataItems(data)

  useEffect(() => {
    fetchOrdersAndItems();
  }, [fetchOrdersAndItems]);

  return (
    <div className="containerr">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Header initial='Детали магазина' previous="Список магазинов" />
      <Title title="Детали магазина" icon={<StoreMallDirectoryRoundedIcon fontSize='large' />} />

      <div className="container-inner">
        {loading ? <Loader /> : (
          <Stack direction='row' gap={1} alignItems='center'>
            <Box display='flex' justifyContent='center' mb={2}>
              <Avatar
                src={data?.logo}
                alt={data?.name}
                variant='rounded'
                sx={{
                  width: 400,
                  height: 200,
                  boxShadow: 'rgb(0 0 0 / 8 %) 0px 1px 2px',
                  border: '1px solid #00000014',
                }}
              />
            </Box>
            <div className="store-container">
              <Typography variant="h6" component="div">
                Информация о магазине
              </Typography>
              <Grid container spacing={0.25}>
                {storeItems.map((items, idx) => (
                  <Grid item xs={12} sm={6} key={idx}>
                    <List>{renderList(items)}</List>
                  </Grid>
                ))}
              </Grid>

            </div>
          </Stack>
        )}
        <div className="orders-card-wrapper">
          <div className="orders-card-header">
            <div className="orders-card-parent__btns">
              {/* <ExportToExcelButtons ordersData={[]} /> */}
              {/* <div> */}
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  scrollButtons='auto'
                  variant='scrollable'
                >
                  <Tab label={`Заказы`} />
                  <Tab label={`Товары`} />
                </Tabs>
              {/* </div> */}
            </div>
          </div>
          {error ? (
            <Typography color="error">Произошла ошибка при загрузке данных.</Typography>
          ) : (
            <OrderGrid
              orders={allData}
              columns={tabIndex === 0 ? storeOrderColumn : storeItemsColumn}
              size={20}
              loading={dataLoading}
              usePagination={false}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default StoreDetail