import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { Search } from '../Search';
import '../scss/InstantSearch.scss'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const SearchModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.key === 'k') {
      event.preventDefault(); 
      handleOpen();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <div className='searchModalInput' onClick={handleOpen}>
        <div>
          <span><SearchRoundedIcon /></span>
          <span>Поиск</span>
        </div>
        <kbd>
          <abbr title='Control'>Ctrl</abbr>
          +K
        </kbd>
      </div>
      {/* <Button variant='black-contained' onClick={handleOpen}>
        Поиск
      </Button> */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={sxStyle}>
          <Search />
        </Box>
      </Modal>
    </div>
  );
};

export default SearchModal;


const sxStyle = {
  width: '100%',
  height: '700px',
  margin: 'auto',
  marginTop: '40px',
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '12px',
  overflowY: 'auto',
  outLine: 'none'
}
