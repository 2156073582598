import React from 'react';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import './Auth.css';
import { FormValidation } from './../../../components/Form/FormValidation/exports';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../../../configs';
import { Loader } from '../../../components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  adminRef,
  accountantRef,
  managerRef,
} from './../../../utils/collectionRefs';
import { getDocs, query, where } from 'firebase/firestore';
import axios from 'axios';

const Auth = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const signIn = async (data) => {
    try {
      setLoading(true);
      const q = query(
        state?.from === 'Админ'
          ? adminRef
          : state?.from === 'Менеджер'
            ? managerRef
            : state?.from === 'Бухгалтер' && accountantRef,
        where('login', '==', data.login)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        throw new Error('Неверный логин или пароль');
      }
      const apiResponse = await axios.post('https://aylex.ru/api/login/', {
        login: 'admin',
        password: '123',
      }); 
      
      if (apiResponse.status !== 200 || !apiResponse.data.access) {
        throw new Error('API аутентификация не удалась');
      }
      localStorage.setItem('authToken', apiResponse.data.access); 
      localStorage.setItem('refreshToken', apiResponse.data.refresh); 
      querySnapshot.forEach(async (doc) => {
        const userData = doc.data();
        const role = state?.from === 'Админ'
          ? 'admin'
          : state?.from === 'Менеджер'
            ? 'manager'
            : state?.from === 'Бухгалтер' && 'accountant';

        if (userData.pin === data.password) {
          await signInAnonymously(auth);
          navigate('/');
          localStorage.setItem('userType', state?.from);
          localStorage.setItem('userName', userData.login);
          localStorage.setItem('roleType', role);
          setLoading(false);
          setError(false);
          reset();
        } else {
          setError(true);
          setLoading(false);
        }
      });
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className='auth-container'>
        <div className='auth-card'>
          {loading ? (
            <Loader height={'0'} />
          ) : (
            <>
              <div style={{ textAlign: 'center' }}>
                <h2>Авторизация</h2>
                <p>Тип аккаунта: {state?.from}</p>
              </div>
              <form
                onSubmit={handleSubmit((data) => signIn(data))}
                className='form'
              >
                <TextField
                  error={errors?.login && true}
                  type='text'
                  label='Логин'
                  variant='outlined'
                  helperText={errors?.login?.message}
                  {...register('login', {
                    required: FormValidation.RequiredInput.required,
                  })}
                />
                <TextField
                  error={errors?.password && true}
                  type='password'
                  label='Пароль'
                  variant='outlined'
                  helperText={errors?.password?.message}
                  {...register('password', {
                    required: FormValidation.RequiredInput.required,
                    // minLength: FormValidation.minLengthValidation
                  })}
                />
                {error && (
                  <span style={{ color: 'red', fontSize: '14px' }}>
                    Неверный логин или пароль
                  </span>
                )}
                <Button variant='contained' type='submit'>
                  Войти
                </Button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Auth;
