import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Stack,
  Autocomplete,
} from '@mui/material';
import axiosInstance from '../../../../../configs/axiosInstance';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../../components/Header/Header';
import { Title } from '../../../../../components/Title/Title';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { userCollectionRef } from '../../../../../utils/collectionRefs';
import { addDoc } from 'firebase/firestore';
import { useFetchData } from '../../../../../hooks/useFetchData';

const EditSubSubCategory = ({ pageTitle }) => {
  const { id } = useParams()
  const [open, setOpen] = useState(false);
  const { handleSubmit, reset, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const { data, loading } = useFetchData("/category/subCategory/");
  const { data: subSubCategory } = useFetchData("/category/subSubCategory", `${id}/`);

  useEffect(() => {
    if (subSubCategory && Object.keys(subSubCategory).length > 0) {
      reset({
        name: subSubCategory.name || '',
        subCategory: subSubCategory.subCategory || null
      });
    }
  }, [subSubCategory, reset]);

  const handleAddSubCategory = async (data) => {
    setOpen(true);

    const subSubCategoryData = {
      ...data,
      subCategory: data?.subCategory?.id
    };
    const historyData = {
      date: new Date(),
      description: `${userType} ${userName} отредактировал подподкатегорию ${data.name}`
    }
    try {
      await axiosInstance.patch(`/category/subSubCategory/${id}/`, subSubCategoryData);
      await addDoc(userCollectionRef, historyData)

      toast.success('Подподкатегория успешно отредактирована', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      navigate('/subSubCategories');
    } catch (e) {
      toast.error('Произошла ошибка при редактировании подподкатегории', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      setOpen(false);
      reset();
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Подподкатегория' initial='Редактировать подподкатегорию' />
      <Title title='Редактировать подподкатегорию' icon={<AddCircleRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <div className="addCourier-wrapper kassa">
          <div className='order-block-head'>
            <h3>Редактировании подподкатегории</h3>
          </div>
          <form className='add-courier-form' onSubmit={handleSubmit(handleAddSubCategory)}>
            <Stack direction='column' gap={2}>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле!' }}
                render={({ field }) => (
                  <TextField
                    label='Название подкатегории'
                    variant='outlined'
                    size='small'
                    helperText={errors?.name?.message}
                    error={errors?.name && true}
                    {...field}
                  />
                )}
              />

              <Controller
                name='subCategory'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле!' }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={data || []}
                    getOptionLabel={(option) => option?.name || ''}
                    onChange={(_, val) => { field.onChange(val) }}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Подкатегория'
                        variant='outlined'
                        size='small'
                        style={{ width: '100%' }}
                        error={!!errors.subCategory}
                        helperText={errors?.subCategory?.message}
                      />
                    )}
                  />
                )}
              />
              <Button
                type='submit'
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
                sx={{ marginTop: '8px' }}
              >
                Сохранить
              </Button>
            </Stack>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          </form>
        </div>

      </div>
    </div>
  );
};
export default EditSubSubCategory
