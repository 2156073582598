import React, { useState } from 'react';
import { Title } from '../../../../components/Title/Title';
import { Header } from '../../../../components/Header/Header';
import { Controller, useForm } from 'react-hook-form';
import { agentsRef, userCollectionRef } from '../../../../utils/collectionRefs';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { doc, setDoc, addDoc } from 'firebase/firestore';
import {TextField,Button,Box,Backdrop,CircularProgress} from '@mui/material';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const AddAgent = ({ pageTitle }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const date = new Date()
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');


  const pinValidationRules = {
    required: 'Обязательное полe!',
    validate: (value) => {
      if (value.length < 6) {
        return 'ПИН должен быть больше 5 символов';
      }
      if (value.length > 6) {
        return 'ПИН не должен быть больше 6 символов';
      }
    },
  };

  const handleAddCourirer = async (data) => {
    setOpen(true);
    try {
      await setDoc(doc(agentsRef, `${data.phone}`), {
        ...data,
        balance: 0,
        avatar: '',
        dateCreated: date,
      });
      setOpen(false);
      navigate('/agents');
      await addDoc(userCollectionRef, {
        date: date,
        description: `${userType} ${userName} добавил агента ${data.name} ${data.surName}`
      })
      toast.success('Агент успешно добавлен', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (e) {
      setOpen(false);
      toast.error('Произошла ошибка при добавлении агента', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      reset();
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Добавить агента' />
        <Title title={'Добавление агента'} icon={<AddCircleIcon fontSize='24' />} />
        <div className='container-inner'>

          <div className='addCourier-wrapper'>
            <div className='order-block-head'>
              <h3>Добавление агента</h3>
            </div>
            <form className='add-courier-form'>
              <Box className='add-courier-form__input'>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Обьязательное полe!' }}
                  render={({ field }) => (
                    <TextField
                      label='Имя'
                      placeholder='Асан'
                      multiline
                      variant='outlined'
                      size='small'
                      helperText={errors?.name?.message}
                      error={errors?.name && true}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='surName'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Обьязательное полe!' }}
                  render={({ field }) => (
                    <TextField
                      label='Фамилия'
                      placeholder='Асанов'
                      variant='outlined'
                      size='small'
                      helperText={errors?.surName?.message}
                      error={errors?.surName && true}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box className='add-courier-form__input'>
                <Controller
                  name='pin'
                  control={control}
                  defaultValue=''
                  rules={pinValidationRules}
                  render={({ field }) => (
                    <TextField
                      label='ПИН'
                      placeholder='010101'
                      variant='outlined'
                      sx={{ flex: 1 }}
                      size='small'
                      type='text'
                      inputProps={{ inputMode: 'numeric' }}
                      helperText={errors?.pin?.message}
                      error={errors?.pin && true}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='phone'
                  control={control}
                  defaultValue={996}
                  rules={{
                    required: 'Обьязательное полe!',
                    pattern: {
                      value: /^996\d{3}\d{2}\d{2}\d{2}$/,
                      message: 'Неверный формат номера',
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label='Моб-номер'
                      placeholder='(996)700777777'
                      variant='outlined'
                      size='small'
                      sx={{ flex: 1 }}
                      type='number'
                      helperText={errors?.phone?.message}
                      error={errors?.phone && true}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Button
                onClick={handleSubmit((data) => handleAddCourirer(data))}
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
                sx={{ marginTop: '10px' }}
              >
                Создать агента
              </Button>
            </form>
          </div>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

export default AddAgent;
