import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Stack,
  Autocomplete,
  Typography,
} from '@mui/material';
import axiosInstance from '../../../../../configs/axiosInstance';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../../components/Header/Header';
import { Title } from '../../../../../components/Title/Title';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { userCollectionRef } from '../../../../../utils/collectionRefs';
import { addDoc } from 'firebase/firestore';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { uploadImage } from '../../../../../hooks/uploadImage';

const EditBrand = ({ pageTitle }) => {
  const { id } = useParams()
  const [open, setOpen] = useState(false);
  const { handleSubmit, reset, control, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const { data: category, loading: categoryLoad } = useFetchData("/category/category/");
  const { data: subCategory, loading: subCatLoading } = useFetchData("/category/subCategory/");
  const { data, loading } = useFetchData("/category/brand", `${id}/`);
  const [imagePreview, setImagePreview] = useState(null);
  const [imgLoading, setImgLoading] = useState(false)

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      reset({
        ...data,
        logo: data.logo || '',
        category: data.category || null,
        subCategory: data.subCategory || null,
      });
      setImagePreview(data.logo || null);
    }
  }, [data, reset]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImgLoading(true);
      try {
        const imageUrl = await uploadImage(file, file.name);
        setImagePreview(imageUrl);
      } catch (error) {
        console.error(error);
      } finally {
        setImgLoading(false);
      }
    }
  };

  const handleCancelImage = () => {
    setImagePreview(null);
  };

  const handleEditBrand = async (data) => {
    setOpen(true);

    const brandData = {
      ...data,
      category: data?.category?.id,
      subCategory: data?.subCategory?.id,
      logo: imagePreview,
    };
    const historyData = {
      date: new Date(),
      description: `${userType} ${userName} отредактировал бренд ${data.name}`
    }
    try {
      await axiosInstance.patch(`/category/brand/${id}/`, brandData);
      await addDoc(userCollectionRef, historyData)

      toast.success('Бренд успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      navigate('/brands');
    } catch (e) {
      toast.error('Произошла ошибка при редактировании бренда', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      setOpen(false);
      reset();
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Редактировать бренд' />
      <Title title='Редактировать бренд' icon={<AddCircleRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <Stack direction='row' alignItems={'center'} gap={4}>

          <div className="addCourier-wrapper kassa">
            <div className='order-block-head'>
              <h3>Редактирование бренда</h3>
            </div>
            <form className='add-courier-form' onSubmit={handleSubmit(handleEditBrand)}>
              <Stack direction='column' gap={2}>
                <Controller
                  name='name'
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Обязательное поле!' }}
                  render={({ field }) => (
                    <TextField
                      label='Название бренда'
                      variant='outlined'
                      size='small'
                      helperText={errors?.name?.message}
                      error={errors?.name && true}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='category'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      options={category || []}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={(_, val) => { field.onChange(val) }}
                      loading={categoryLoad}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Категория'
                          variant='outlined'
                          size='small'
                          style={{ width: '100%' }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name='subCategory'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disablePortal
                      options={subCategory || []}
                      getOptionLabel={(option) => option?.name || ''}
                      onChange={(_, val) => { field.onChange(val) }}
                      loading={subCatLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Подкатегория'
                          variant='outlined'
                          size='small'
                          style={{ width: '100%' }}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="logo"
                  control={control}
                  rules={{ required: '*Выберите фото' }}
                  defaultValue=""
                  render={({ field }) => (
                    <Stack direction='row' gap={1} alignItems={'center'}>
                      <Button
                        variant="outlined"
                        component="label"
                        size="small"
                        sx={{ marginTop: 1 }}
                        color={errors?.logo ? 'error' : 'primary'}
                      >
                        {imgLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          'Выберите фото'
                        )}
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={(e) => {
                            handleFileChange(e);
                            field.onChange(e.target.files[0]);
                          }}
                        />
                      </Button>

                      <input {...field} type="hidden" value={imagePreview || field.value} />

                      {errors?.logo && (
                        <Typography variant='caption' color={errors?.logo ? 'error' : ''}>
                          {errors?.logo.message}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
                <Button
                  type='submit'
                  size='large'
                  variant='contained'
                  style={{ background: '#000b1f' }}
                  sx={{ marginTop: '8px' }}
                >
                  Сохранить
                </Button>
              </Stack>
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </form>
          </div>
          {imagePreview && (
            <Stack>
              <span>Текущее фото</span>
              <div style={{ width: '300px', height: 'auto', borderRadius: '8px', marginTop: '4px' }} >
                <img
                  src={imagePreview}
                  alt="Предпросмотр"
                  style={{ width: '100%', objectFit: 'contain', borderRadius: '8px' }}

                />
              </div>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleCancelImage}
              >
                Отменить
              </Button>
            </Stack>
          )}
        </Stack>
      </div>
    </div >
  );
};
export default EditBrand
