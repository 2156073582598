import React from 'react'
import { Helmet } from 'react-helmet'
import { Title } from '../../../../../components/Title/Title'
import { Header } from '../../../../../components/Header/Header'
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { useParams } from 'react-router-dom';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { Box, Divider, Step, StepLabel, Stepper } from '@mui/material';
import { storeOrderStatus } from '../../../../../utils';
import dayjs from 'dayjs';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import OrderDetailBox from '../../../../../components/OrderDetailBox';
import OrderGrid from '../../../../../components/OrderGrid ';
import { storeItemsColumn } from '../constants/MarketPlaceColumn';

const StoreOrderDetail = ({ pageTitle }) => {
  const { id } = useParams()
  const { data, loading, error } = useFetchData('/core/modelOrder', `${id}/`)
  console.log(data)

  function renderOrderStatus(order) {
    const isRejectedOrCancelled = order.status === 'Rejected' || order.status === 'Cancelled';

    return (
      <Stepper
        activeStep={
          isRejectedOrCancelled
            ? 4
            : storeOrderStatus.findIndex((status) => status.value === order.status)
        }
        alternativeLabel
        sx={{ overflowX: 'scroll', paddingBottom: '20px' }}
        className='scrollable'
      >
        {isRejectedOrCancelled ? (
          <Step key={order.status} sx={{ minWidth: '110px' }}>
            <StepLabel sx={{ fontSize: '12px', color: 'red' }} error>
              {storeOrderStatus.find((status) => status.value === order.status)?.name}
            </StepLabel>
          </Step>
        ) : (
          storeOrderStatus?.slice(0, 4).map((label) => (
            <Step key={label.value}>
              <StepLabel sx={{ fontSize: '12px', minWidth: '110px' }}>
                {label.name}
              </StepLabel>
            </Step>
          ))
        )}
      </Stepper>
    );
  }

  const OrderDetails = ({ store, order }) => {
    return (
      <>
        <OrderDetailBox
          title='Магазин'
          items={[
            { label: 'Название', value: store?.name },
            { label: 'Номер телефона', value: store?.phone },
            { label: 'Город', value: store?.city_name },
            { label: 'Адрес', value: store?.address },
            {
              label: 'О магазине',
              value: store?.about || '----------',
            },
          ]}
        />
      </>
    );
  };

  return (
    <div className='container'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous="" initial="Детали заказа магазина" />
      <Title title="Детали заказа магазина" icon={<DescriptionRoundedIcon fontSize='24' />} />
      <div className="container-inner">
        <div className="orders-more-wrapper">
          <div className='orders-more-box'>
            <div className='orders-more-box-header'>
              <h3>Статус заказа</h3>
            </div>
            <Divider />
            <Box sx={{ width: '100%' }}>{renderOrderStatus(data)}</Box>
          </div>
          <OrderDetails title='Заказ' store={data.store} order={data} />

          <div className='order-detail-wrap'>
            <div className='orders-more-box'>
              <div className='orders-more-box-header'>
                <h3>Детали заказа</h3>
              </div>
              <Divider />
              <ul className='orders-more-body-list'>
                <li>
                  <span>ID</span>
                  <span>{data?.id}</span>
                </li>
                <Divider />
                <li>
                  <span>PID</span>
                  <span>{data?.pid}</span>
                </li>
                <Divider />
                <li>
                  <span>Дата</span>
                  <span>{dayjs(data?.dateCreated).format('DD/MM/YYYY')}</span>
                </li>
                <Divider />
                <li>
                  <span>Название</span>
                  <span>{data?.id}</span>
                </li>

                <Divider />
                <li>
                  <span>Клиент</span>
                  <span>{data?.clientName} ({data?.clientPhone})</span>
                </li>

                <Divider />
                <li>
                  <span>Получатель</span>
                  <span>{data?.receiverName} ({data?.receiverPhone})</span>
                </li>
                <Divider />
                <li>
                  <span>Адрес</span>
                  <span>{data?.cityName} / {data?.address}</span>
                </li>

                <Divider />
                <li>
                  <span>Метод оплаты</span>
                  <span>{!data?.paymentSource === "" ? data?.paymentSource : "------------"}</span>
                </li>
                <Divider />
                <li>
                  <span>Информация об оплате</span>
                  <span>{!data?.paymentInfo === "" ? data?.paymentInfo : "------------"}</span>
                </li>
                <Divider />
                <li>
                  <span>Стоимость доставки</span>
                  <span>{data?.deliveryCost}c</span>
                </li>
                <Divider />
                <li>
                  <span>Общая стоимость</span>
                  <span>{data?.totalCost}c</span>
                </li>
                <Divider />
                <li>
                  <span>Статус оплаты</span>
                  <span className='order-more-list-type'>
                    {!data?.isPaid ? 'Не оплачен' : 'Оплачен'}
                    {!data?.isPaid ? (
                      <DoNotDisturbAltRoundedIcon
                        fontSize='small'
                        sx={{ color: '#b42318' }}
                      />
                    ) : (
                      <CheckCircleRoundedIcon
                        fontSize='small'
                        sx={{ color: '#0b815a' }}
                      />
                    )}
                  </span>
                </li>
                <Divider />
                <li className='order-more-list-comments'>
                  <span>Коментарии</span>
                  <span className='order-more-list-type-edit'>
                    {data?.comment ? data?.comment : '----------'}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <OrderGrid
          orders={data?.items}
          columns={storeItemsColumn}
          size={20}
          loading={loading}
          usePagination={false}
        /> */}
      </div>
    </div>
  )
}

export default StoreOrderDetail