import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import dayjs from "dayjs";
import { storeOrderStatusLabels } from "../../../../../utils";
import { getStatusClass } from "../../Orders/helpers/getStatusClass";

const getStatusLabel = (status) => {
  return storeOrderStatusLabels[status];
};

export const CategoriesColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Редактирование' arrow>
        <Link to={`/categories/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "icon",
    headerName: "Иконка",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "40px", height: "40px" }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },

  {
    field: "priority",
    headerName: "Приоритет",
    width: 120,
    sortable: true,

  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const SubCategoryColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Редактирование' arrow>
        <Link to={`/subCategories/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "icon",
    headerName: "Иконка",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "40px", height: "40px" }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "category",
    headerName: "Категория",
    flex: 1,
    renderCell: (params) => (
      <div>
        <p>{params?.value.name}</p>
      </div>
    ),
  },

  {
    field: "priority",
    headerName: "Приоритет",
    width: 120,
    sortable: true,

  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];
export const SubSubCategoryColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Редактирование' arrow>
        <Link to={`/subSubCategories/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },

  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "subCategory",
    headerName: "Подкатегория",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value.name}</p>
      </div>
    ),
  },

  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const ColorColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Редактирование' arrow>
        <Link to={`/colors/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "nameRu",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "nameKg",
    headerName: "Аталышы",
    flex: 1,
  },
  {
    field: "nameEn",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "color",
    headerName: "Цвет",
    width: 120,
    sortable: false,
    renderCell: (params) => (
      <div
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: params.value,
          borderRadius: "50%",
        }}
      />
    ),
  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`colors/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const SizeColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Редактирование' arrow>
        <Link to={`/sizes/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "name",
    headerName: "Название",
    flex: 0.5,
    sortable: true,
  },
  {
    field: "desc",
    sortable: false,
    headerName: "Описание",
    flex: 1,
  },
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`sizes/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const brandColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Редактирование' arrow>
        <Link to={`/brands/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "logo",
    headerName: "Логотип",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "40px", height: "40px" }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "category",
    headerName: "Категория",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.name}</p>
      </div>
    ),
  },
  {
    field: "subCategory",
    headerName: "Подкатегория",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.name}</p>
      </div>
    ),
  },

  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];
export const storeColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Детали' arrow>
        <Link to={`/stores/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 10,
  },
  {
    field: "logo",
    headerName: "Логотип",
    width: 80,
    sortable: false,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Иконка"
        style={{ width: "100px", height: "40px", objectFit: 'cover' }}
      />
    ),
  },
  {
    field: "name",
    headerName: "Название",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "Тел-номер",
    flex: 1,
  },

  {
    field: "author",
    headerName: "Автор",
    flex: 1,
  },
  {
    field: "address",
    headerName: "Адрес",
    flex: 1,
  },


  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const storeOrderColumn = [
  {
    field: "actions",
    headerName: "Действия",
    width: 90,
    sortable: false,
    renderCell: (params) => (
      <Tooltip title='Детали' arrow>
        <Link to={`/storeOrders/${params.id}`} target='_blank'>
          <IconButton color='primary' aria-label='edit'>
            <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
          </IconButton>
        </Link>
      </Tooltip>
    ),
  },
  {
    field: "id",
    headerName: "ID",
    width: 10,
  },
  {
    field: "dateCreated",
    headerName: "Дата создания",
    width: 130,
    renderCell: (params) => (
      <div>
        <time>{dayjs(params.value).format('DD/MM/YYYY')}</time>
      </div>
    ),
  },

  {
    field: "client",
    headerAlign: 'center',
    headerName: "Клиент",
    sortable: false,
    width: 154,
    renderCell: (params) => {
      return (
        <div className='orders-card-values client'>
          <span>{params.row.clientName}</span>
          <span>{params.row.clientPhone}</span>
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Статус",
    width: 180,
    sortable: false,
    headerAlign: 'center',
    renderCell: (params) => {
      return (
        <strong className={getStatusClass(params.value)}>
          {getStatusLabel(params.value)}
        </strong>
      );
    },
  },

  {
    field: "deliveryCost",
    headerName: "Стоимость доставки",
    width: 180,
    renderCell: (params) => {
      return <div>{params.value}с</div>;
    },
  },
  {
    field: "comment",
    headerName: "Комментарий",
    sortable: false,
    flex: 1,
  },


  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];

export const storeItemsColumn = [
  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 90,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Детали' arrow>
  //       <Link to={`/stores/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
  {
    field: "id",
    headerName: "ID",
    width: 10,
  },
  {
    field: "dateCreated",
    headerName: "Дата создания",
    width: 130,
    renderCell: (params) => (
      <div>
        <time>{dayjs(params.value).format('DD/MM/YYYY')}</time>
      </div>
    ),
  },
  {
    field: "name",
    headerName: "Название",
    width: 154,
  },
  {
    field: "category",
    headerName: "Категория",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.name}</p>
      </div>
    ),
  },
  {
    field: "subCategory",
    headerName: "Подкатегория",
    width: 115,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.name}</p>
      </div>
    ),
  },
  {
    field: "subSubCategory",
    headerName: "Подподкатегория",
    width: 145,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.name}</p>
      </div>
    ),
  },
  {
    field: "brand",
    headerName: "Бренд",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.name}</p>
      </div>
    ),
  },
  {
    field: "sizes",
    headerName: "Размер",
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <div>
        <p>{params?.value?.[0].name}</p>
      </div>
    ),
  },
  {
    field: "colors",
    headerName: "Цвет",
    width: 120,
    sortable: false,
    renderCell: (params) => (
      <div
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: params?.value?.[0]?.color,
          borderRadius: "50%",
        }}
      />
    ),
  },
  {
    sortable: false,
    field: "desc",
    headerName: "Описание",
    width: 450,
  },


  // {
  //   field: "actions",
  //   headerName: "Действия",
  //   width: 120,
  //   sortable: false,
  //   renderCell: (params) => (
  //     <Tooltip title='Редактировать' arrow>
  //       <Link to={`subCategories/${params.id}`} target='_blank'>
  //         <IconButton color='primary' aria-label='edit'>
  //           <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
  //         </IconButton>
  //       </Link>
  //     </Tooltip>
  //   ),
  // },
];



