import React, { useEffect, useMemo, useState } from 'react';
import { count, getAggregateFromServer, getCountFromServer, getDocs, onSnapshot, orderBy, query, sum, Timestamp, where, } from 'firebase/firestore';
import { orderStatus, typeOfOrder, statusLabels, alternativeStatusLabels, paymentPerson, paymentMethodd, orderDebtLabel, orderWaitingStatus, } from '../../../../utils/index';
import { Stack, Button, MenuItem, TextField, } from '@mui/material';
import { Title } from '../../../../components/Title/Title';
import { Header } from '../../../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { citiesRef, couriersRef, managerRef, orderRef } from '../../../../utils/collectionRefs';
import OrderSort from '../../../../components/OrderSort';
import { fieldsToSearchOrder, headers } from './constants';
import OrderGrid from '../../../../components/OrderGrid ';
import OrderStatistics from '../../../../components/OrderStatistics';
import { Helmet } from 'react-helmet';
import { handleCopyOrder } from './helpers/handleCopyOrder';
import { handleChangeIsWaitingStatus, handleChangeStatus } from './helpers/handleChangeStatus';
import { orderColumns } from './helpers/orderColumns';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import './scss/Orders.scss';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import ExportToExcel from '../../../../components/Buttons/ExportToExcell';
import { orderExcelDataFormatter } from './helpers/excelDataFormatter';
import { getCourierName } from './helpers/getCourierName';

const Orders = ({ pageTitle }) => {
  const [orders, setOrders] = useState(null);
  const [couriers, setCouriers] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [cities, setCities] = useState(null);
  const [isDebt, setIsDebt] = useState("Все");
  const [managers, setManagers] = useState(null);
  const [search, setSearch] = useState('');
  const [aggregates, setAggregates] = useState({})
  const [selectedFilters, setSelectedFilters] = useState({
    cityTo: '',
    cityFrom: '',
    packageType: '',
    status: '',
    whoPays: '',
  });

  const USERTYPE = localStorage.getItem('userType');
  const navigate = useNavigate();

  const clearFilter = () => {
    window.location.reload()
    // setSelectedFilters({
    //   cityTo: '',
    //   cityFrom: '',
    //   packageType: '',
    //   status: '',
    //   whoPays: '',
    // })
    // setSearch('');
    // setSelectedDate(null)
    // setSelectedDate2(null)
  }

  useEffect(() => {
    let q = query(orderRef, orderBy('dateCreated', 'desc'));
    let redemptionQuery = query(orderRef, orderBy('dateCreated', 'desc'), where('redemption', '>', 0));
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    Object?.keys(selectedFilters)?.forEach((field) => {
      const value = selectedFilters[field];

      if (value !== 'Все' && value !== '') {
        q = query(q, where(field, '==', value));
        redemptionQuery = query(redemptionQuery, where(field, '==', value));
      }
    });

    if (isDebt === true) {
      q = query(q, where('redemption', '<', 0));
      redemptionQuery = query(redemptionQuery, where('redemption', '<', 0));
    }

    if (selectedDate && selectedDate2) {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate2);
      endOfDay.setHours(0, 0, 0, 0);
      q = query(
        q,
        where('dateCreated', '>=', startOfDay),
        where('dateCreated', '<=', endOfDay)
      );
      redemptionQuery = query(
        redemptionQuery,
        where('dateCreated', '>=', startOfDay),
        where('dateCreated', '<=', endOfDay)
      );
    }
    if (
      !selectedDate && !selectedDate2 &&
      (!selectedFilters.dateCreated || selectedFilters.dateCreated === 'Все' || selectedFilters.dateCreated === '')
    ) {
      q = query(q);
      redemptionQuery = query(redemptionQuery);
    }


    const startOfTodayTimestamp = Timestamp.fromDate(startOfToday);
    const endOfTodayTimestamp = Timestamp.fromDate(endOfToday);

    const todayOrdersQuery = query(
      orderRef,
      where('dateCreated', '>=', startOfTodayTimestamp),
      where('dateCreated', '<=', endOfTodayTimestamp)
    );

    const fetchAggregates = async () => {
      try {
        const todayOrdersCountSnapshot = await getCountFromServer(todayOrdersQuery);
        const totalOrdersForToday = todayOrdersCountSnapshot.data().count;
        const aggregateSnapshot = await getAggregateFromServer(q, {
          count: count(),
          totalCost: sum('cost'),
        });
        const redemptionSnapshot = await getAggregateFromServer(redemptionQuery, {
          totalRedemption: sum('redemption'),
        });
        setAggregates({ ...aggregateSnapshot.data(), totalOrdersForToday });

        const debtsQuery = query(q, where('redemption', '<', 0));
        const debtsSnapshot = await getDocs(debtsQuery);
        const totalDebts = debtsSnapshot.docs
          .map(doc => doc.data().redemption)
          .reduce((total, debt) => total + debt, 0);

        setAggregates((prevAggregates) => ({
          ...prevAggregates,
          ...aggregateSnapshot.data(),
          ...redemptionSnapshot.data(),
          totalDebts,
        }))
      } catch (error) {
        console.error('Error fetching aggregates:', error);
      }
    };


    const unsubscribe = onSnapshot(q, (snapshot) => {
      fetchAggregates()
      const newOrders = snapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }))
      setOrders(newOrders);
    });

    return unsubscribe;
  }, [selectedDate, selectedDate2, selectedFilters, isDebt]);

  useEffect(() => {
    fetchData(citiesRef, 'name', 'asc', setCities);
    fetchData(managerRef, 'name', 'asc', setManagers);
    fetchData(couriersRef, 'name', 'asc', setCouriers);
  }, []);

  const fetchData = async (collectionRef, sortField, sortOrder, setData) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder))
      );
      const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setData(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    if (!couriers?.length || !orders) return;


    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        const courierOneInfo = getCourierName(order.courierOne, couriers);
        const courierTwoInfo = getCourierName(order.courierTwo, couriers);

        return {
          ...order,
          courierOneName: courierOneInfo.name,
          courierTwoName: courierTwoInfo.name,
        };
      });

      if (JSON.stringify(prevOrders) === JSON.stringify(updatedOrders)) {
        return prevOrders;
      }
      return updatedOrders;
    });
  }, [couriers, orders]);

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event?.target?.value);
  }

  const orderCreatorToSort = createOrderSortHandler('creator');
  const orderCityToSort = createOrderSortHandler('cityTo');
  const orderCityFromSort = createOrderSortHandler('cityFrom');
  const orderTypeSort = createOrderSortHandler('packageType');
  const orderIsWaitingStatusSort = createOrderSortHandler('isWaiting');
  const orderStatusSort = createOrderSortHandler('status');
  const orderWhoPaysSort = createOrderSortHandler('whoPays');
  const orderPaymentMethodSort = createOrderSortHandler('paymentMethod');

  const handleDateChange = (field, value) => {
    if (field === 'selectedDate') {
      setSelectedDate(value);
    } else if (field === 'selectedDate2') {
      setSelectedDate2(value);
    }
  };

  const handleDateChange1 = (e) => handleDateChange('selectedDate', e.target.value);
  const handleDateChange2 = (e) => handleDateChange('selectedDate2', e.target.value);

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };


  const handleStatusChange = async (id, newStatus) => {
    await handleChangeStatus({
      orders: orders,
      id,
      newStatus,
      dataRef: orderRef,
      statusLabels,
      alternativeStatusLabels,
    });
  };
  const handleIsWaitingStatusChange = async (id, newStatus) => {
    await handleChangeIsWaitingStatus({
      id,
      newStatus,
      dataRef: orderRef,
    });
  };
  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/update/${params.id}`),
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const filteredData = useMemo(() => {
    if (!orders) {
      return {
        filteredOrders: [],
        aggregates: {
          count: 0,
          totalCost: 0,
          totalRedemption: 0
        }
      };
    }

    const filteredOrders = orders.filter((order) =>
      fieldsToSearchOrder.some((field) => {
        const value = order[field];
        return value && value.toLowerCase().includes(search.toLowerCase());
      })
    );

    const aggregates = {
      count: filteredOrders.length,
      totalCost: filteredOrders.reduce((sum, order) => sum + (order.cost || 0), 0),
      totalRedemption: filteredOrders
        .filter((order) => order.redemption > 0)
        .reduce((sum, order) => sum + order.redemption, 0),
      totalDebts: filteredOrders
        .filter((order) => order.redemption < 0)
        .reduce((sum, order) => sum + order.redemption, 0),
    };

    return { filteredOrders, aggregates };
  }, [orders, search]);


  const columns = orderColumns({ actionsConfig: actionsConfig, path: '/order/details', editable: true, handleIsWaitingStatusChange });

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Активные заказы' />
        <Title
          title={'Активные заказы'}
          icon={<AssignmentRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <OrderStatistics aggregates={search ? filteredData.aggregates : aggregates} totalOrdersForToday totalDebts />

          <div className='orders-sorting-header clear-btn'>
            <Button variant='contained' color='inherit' onClick={clearFilter} sx={{ ml: 'auto' }}>
              <FilterAltOffRoundedIcon />
            </Button>
            <Stack
              sx={{ width: '100%', flexWrap: 'wrap' }}
              direction='row'
              gap={2}
            >
              <OrderSort
                sortKey='По типу'
                defaultValue=''
                options={typeOfOrder}
                onChange={orderTypeSort}
              />
              <OrderSort
                sortKey='Кто оплачивает'
                defaultValue=''
                options={paymentPerson}
                onChange={orderWhoPaysSort}

              />
              <OrderSort
                sortKey='По методу оплату'
                defaultValue=''
                options={paymentMethodd}
                onChange={orderPaymentMethodSort}
              />
              <OrderSort
                sortKey='Долги'
                defaultValue=''
                options={orderDebtLabel}
                onChange={(e) => setIsDebt(e.target.value === 'true' ? true : e.target.value)}
              />
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='По создателю'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCreatorToSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {managers?.map((option) => (
                    <MenuItem key={option.login} value={option.login}>
                      {option.login}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Откуда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityFromSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Куда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityToSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <OrderSort
                sortKey='По статусу'
                defaultValue=''
                options={orderStatus}
                onChange={orderStatusSort}
                value={selectedFilters.status}
              />
              <OrderSort
                sortKey='По ожиданию'
                defaultValue=''
                options={orderWaitingStatus.slice(0, -1)}
                onChange={orderIsWaitingStatusSort}
              />
              <div className='order-sort order-sort-date'>
                <label htmlFor='orderDate' className='orders-sort-date-label'>
                  От
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate'
                    id='orderDate'
                    value={selectedDate || ''}
                    onChange={handleDateChange1}
                  />
                </label>
                <span></span>
                <label htmlFor='orderDate2' className='orders-sort-date-label'>
                  До
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate2'
                    id='orderDate2'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                  />
                </label>
              </div>
            </Stack>
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  {USERTYPE === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/order/add')}>
                      Новый заказ +
                    </Button>
                  )}
                  <ExportToExcel
                    firestoreRef={orderRef}
                    selectedFilters={selectedFilters}
                    headers={headers}
                    selectedDate={selectedDate}
                    selectedDate2={selectedDate2}
                    fileName="Список активных заказов"
                    dateField="dateCreated"
                    dataFormatter={orderExcelDataFormatter}
                  />
                </div>
                <div style={{ width: '41%' }}>
                  <SearchInput
                    label='Поиск'
                    placeholder='Введите'
                    variant='outlined'
                    onChange={handleSearchOrder}
                  />
                </div>
              </div>
            </div>
            <OrderGrid
              orders={filteredData.filteredOrders || orders}
              columns={columns}
              size={100}
              handleChangeStatus={handleStatusChange} />

          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;