import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { doc, onSnapshot, addDoc, updateDoc, where, getDocs, query, orderBy, deleteDoc, getCountFromServer, startAfter, limit, Timestamp, getAggregateFromServer, sum } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Loader } from '../../../../components/Loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { db, handleSignOut } from '../../../../configs';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Rating, Stack, TextField, ToggleButton, ToggleButtonGroup, } from '@mui/material';
import { Box } from '@mui/system';
import avatar from '../../../../assets/images/avatar.svg';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { completedOrdersRef, couriersRef, orderRef, returnedOrderRef, transactionsRef } from '../../../../utils/collectionRefs';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import OrderGrid from '../../../../components/OrderGrid ';
import './scss/CourierDetail.scss';
import { Helmet } from 'react-helmet';
import { transActionColumns } from '../Transaction/helpers/transactionsColumn';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { countOfOrdersCard } from './constants';
import { fieldsToSearchOrder, headers, headersForCompletedOrders } from '../Orders/constants';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import OrderSort from '../../../../components/OrderSort';
import { typeOfTransactionLables } from '../Transaction/constants';
import { headersFotTransactions2, returnedOrderStatusLabels } from '../Orders/constants';
import ExportToExcel from '../../../../components/Buttons/ExportToExcell';
import { transactionExcelDataFormatter } from '../Transaction/helpers/excelDataFormatter';
import { orderColumns } from '../Orders/helpers/orderColumns';
import { handleCopyOrder } from '../Orders/helpers/handleCopyOrder';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { handleChangeStatus } from '../Orders/helpers/handleChangeStatus';
import { returnedOrderAlternativeStatusLabels } from './../Orders/constants/index';
import { completedOrderExcelDataFormatter, orderExcelDataFormatter } from '../Orders/helpers/excelDataFormatter';
import { getDayAgo, getEndOfDay } from '../../../../utils/dateFormatter';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { useKassa } from '../Kassa/helpers/useKassa';
import axios from 'axios';
import { getAuthToken, verifyToken } from '../../../Auth/Login/authToken';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';

const Courier = ({ pageTitle }) => {
  const { id } = useParams();
  const [courier, setCourier] = useState(null);
  const [orders, setOrders] = useState([]);
  const [transActions, setTransActions] = useState(null);
  const { kassa } = useKassa();

  const [pageSize, setPageSize] = useState(20)
  const [lastVisible, setLastVisible] = useState(null)
  const [page, setPage] = useState(0)
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [isLoadingTransActions, setIsLoadingTransActions] = useState(false);
  const [showTransactions, setShowTransactions] = useState(true);

  const [totalCount, setTotalCount] = useState({ active: 0, delivered: 0 })
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [operation, setOperation] = useState('');
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({ type: '' });
  const [aggregates, setAggregates] = useState({ cost: 0, redemption: 0 })
  const [activeButton, setActiveButton] = useState('transactions');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleDateChange2 = (e) => setSelectedDate2(e.target.value);

  const todayStart = getDayAgo(0);
  const todayEnd = getEndOfDay(0);

  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');

  const navigate = useNavigate();

  const startTimestamp = Timestamp.fromDate(todayStart);
  const endTimestamp = Timestamp.fromDate(todayEnd);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const typeOfСonsumption = watch('transactionType')

  const transactionQuery = query(
    transactionsRef,
    where('user', '==', id)
  )

  const returnedOrdersQueryForToday = query(
    returnedOrderRef,
    orderBy('dateDelivered', 'desc'),
    where('lastCourier', '==', id),
    where('status', '==', 'status_rejected_courier'),
    where('dateDelivered', '>=', startTimestamp),
    where('dateDelivered', '<=', endTimestamp)
  );
  const activeOrdersQueryForToday = query(
    orderRef,
    orderBy('dateCreated', 'desc'),
    where('lastCourier', '==', id),
    where('dateCreated', '>=', startTimestamp),
    where('dateCreated', '<=', endTimestamp)
  );
  const activeOrdersQuery = query(
    orderRef,
    orderBy('dateCreated', 'desc'),
    where('lastCourier', '==', id),
  );
  const deliveredOrdersQueryForToday = query(
    completedOrdersRef,
    orderBy('dateDelivered', 'desc'),
    where('lastCourier', '==', id),
    where('dateDelivered', '>=', startTimestamp),
    where('dateDelivered', '<=', endTimestamp)
  );
  const deliveredOrdersQuery = query(
    completedOrdersRef,
    orderBy('dateDelivered', 'desc'),
    where('lastCourier', '==', id),
  );


  useEffect(() => {
    const checkTokenAndRedirect = async () => {
      try {
        await verifyToken();
        console.log('Токен проверен и актуален.');
      } catch (error) {
        console.error('Ошибка при проверке токена:', error);
        handleSignOut()
      }
    };
    checkTokenAndRedirect();
  }, [id]);

  const handleButtonGroupChange = (event, newType) => {
    if (newType !== null) {
      setActiveButton(newType);

      switch (newType) {
        case 'transactions':
          handleTransactionsClick();
          break;
        case 'activeToday':
          fetchOrdersForToday(activeOrdersQueryForToday, 'orders');
          break;
        case 'active':
          fetchOrdersForToday(activeOrdersQuery, 'orders');
          break;
        case 'completed':
          fetchOrdersForToday(deliveredOrdersQuery, 'completed');
          break;
        case 'completedToday':
          fetchOrdersForToday(deliveredOrdersQueryForToday, 'completed');
          break;
        case 'returnedToday':
          fetchOrdersForToday(returnedOrdersQueryForToday, 'returned');
          break;
        case 'returned':
          handleReturnedOrdersClick();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (typeOfСonsumption === 'admin_minus') {
      setValue('kassa', '', { shouldValidate: true });
    } else {
      setValue('kassa', '');
    }
  }, [typeOfСonsumption, setValue]);

  useEffect(() => {
    const getCourier = () => {
      const docRef = doc(couriersRef, id);

      const unsubscribe = onSnapshot(docRef, async (docSnap) => {
        if (docSnap.exists()) {
          const courierData = docSnap.data();

          setCourier({ ...courierData, id: docSnap.id });
        } else {
          console.log("Document doesn't exist");
        }
      });

      return () => {
        unsubscribe();
      };
    };

    getCourier();
  }, [id]);

  useEffect(() => {
    if (open) {
      if (operation === 'add') {
        setValue('transactionType', 'admin_plus');
      } else if (operation === 'subtract') {
        setValue('transactionType', 'admin_minus');
      }
    }
  }, [open, operation, setValue]);

  const fetchCountOfOrders = useCallback(async () => {
    try {
      const activeTypeOfQuery = query(
        orderRef,
        where('couriersForFilter', 'array-contains', id)
      )

      const completedOrdersQuery = query(
        completedOrdersRef,
        where('couriersForFilter', 'array-contains', id)
      );

      const returnedTypeOfQuery = query(
        returnedOrderRef,
        where('lastCourier', '==', id),
        where('status', '==', 'status_rejected_courier'),
      )

      const activeOrdersSnapshot = await getCountFromServer(activeTypeOfQuery);
      const completedOrdersSnapshot = await getCountFromServer(completedOrdersQuery);
      const returnedOrdersSnapshot = await getCountFromServer(returnedTypeOfQuery);

      setTotalCount({
        active: activeOrdersSnapshot.data().count,
        delivered: completedOrdersSnapshot.data().count,
        returned: returnedOrdersSnapshot.data().count,
      });

    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }, [id]);

  const fetchOrdersForToday = async (query, type) => {
    setIsLoadingOrders(true)
    setShowTransactions(false);
    setTransActions([])
    try {
      const aggregation = await getAggregateFromServer(query, {
        sumCost: sum('cost'),
        sumRedemption: sum('redemption'),
      });

      const totalCost = aggregation.data().sumCost || 0;
      const totalRedemption = aggregation.data().sumRedemption || 0;

      const querySnapshot = await getDocs(query);
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      setAggregates({ cost: totalCost, redemption: totalRedemption });
      setOrders({ data, type });
      setIsLoadingOrders(false);
    } catch (error) {
      console.error('Ошибка при получении заказов:', error);
      setIsLoadingOrders(false);
    }
  };

  const fetchTransactions = useCallback(async () => {
    setIsLoadingTransActions(true);
    setOrders([]);
    let q = query(transactionsRef, orderBy('date', 'desc'), where('user', '==', id));

    Object.keys(selectedFilters).forEach((field) => {
      const value = selectedFilters[field];
      if (value !== 'Все' && value !== '') {
        q = query(q, where(field, '==', value));
      }
    });

    if (selectedDate && selectedDate2) {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate2);
      endOfDay.setHours(23, 59, 59, 999);
      q = query(q, where('date', '>=', startOfDay), where('date', '<=', endOfDay));
    }

    if (page > 0 && lastVisible) {
      q = query(q, startAfter(lastVisible), limit(pageSize));
    } else {
      q = query(q, limit(pageSize));
      setLastVisible(null);
    }

    try {
      let countQuery = query(transactionsRef, where('user', '==', id));
      Object.keys(selectedFilters).forEach((field) => {
        const value = selectedFilters[field];
        if (value !== 'Все' && value !== '') {
          countQuery = query(countQuery, where(field, '==', value));
        }
      });
      if (selectedDate && selectedDate2) {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate2);
        endOfDay.setHours(23, 59, 59, 999);
        countQuery = query(countQuery, where('date', '>=', startOfDay), where('date', '<=', endOfDay));
      }

      const countSnapshot = await getCountFromServer(countQuery);
      setTotalRowCount(countSnapshot.data().count);

      const transactionSnapshot = await getDocs(q);
      const transactionData = transactionSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      if (transactionSnapshot.docs.length > 0) {
        setLastVisible(transactionSnapshot.docs[transactionSnapshot.docs.length - 1]);
      } else {
        setLastVisible(null);
      }

      setTransActions(transactionData);
      setIsLoadingTransActions(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setIsLoadingTransActions(false);
    }
  }, [id, selectedFilters, selectedDate, selectedDate2, page, pageSize])

  const ferchReturnedOrders = async () => {
    setIsLoadingOrders(true);
    setTransActions([]);
    try {
      const rejectedOrdersQuery = query(
        returnedOrderRef,
        orderBy('dateCreated', 'desc'),
        where('lastCourier', '==', id),
        where('status', '==', 'status_rejected_courier')
      );
      const aggregation = await getAggregateFromServer(rejectedOrdersQuery, {
        sumCost: sum('cost'),
        sumRedemption: sum('redemption'),
      });
      const totalCost = aggregation.data().sumCost || 0;
      const totalRedemption = aggregation.data().sumRedemption || 0;

      const querySnapshot = await getDocs(rejectedOrdersQuery);
      const rejectedOrders = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));

      setAggregates({ cost: totalCost, redemption: totalRedemption });
      setOrders({ data: rejectedOrders, type: 'returned' });
      setIsLoadingOrders(false);
    } catch (error) {
      console.error('Error fetching rejected orders:', error);
      setIsLoadingOrders(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    fetchCountOfOrders()
  }, [fetchCountOfOrders])

  const handleDeleteCourier = async () => {
    try {
      await updateDoc(doc(couriersRef, id), { active: false });
      toast.success('Курьер успешно был удален', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      navigate('/couriers')
    } catch (error) {
      toast.error('Произошла ошибка при удалении курьера:', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      console.error('Error deleting courier:', error);
    } finally {
      setOpenDeleteModal(false)
    }
  };

  const handleOpen = (op) => {
    setOperation(op);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmitTransaction = async (data) => {
    let loadingToastId;
    const newAmount = parseInt(data.amount) * (operation === 'subtract' ? -1 : 1);
    const newBalanceOfCourier = { balance: (courier?.balance || 0) + newAmount, }

    const transactionData = {
      balanceBefore: courier?.balance || 0,
      balanceAfter: newBalanceOfCourier?.balance,
      comment: data.comment ?? '',
      creator: roleType,
      creatorName: userName,
      date: new Date(),
      type: data.transactionType,
      order: '',
      sum: newAmount,
      user: id,
    };

    try {
      handleClose()
      loadingToastId = toast.loading('Изменение баланса', { autoClose: false });
      await updateDoc(doc(db, 'couriers', id), newBalanceOfCourier);
      toast.success('Баланс успешно обновлен');
      toast.dismiss(loadingToastId);

      loadingToastId = toast.loading('Добавление транзакции...', { autoClose: false });
      await addDoc(transactionsRef, transactionData);
      toast.success('Транзакция успешно добавлена');
      toast.dismiss(loadingToastId);

      if (data.kassa) {
        const newAmount = parseInt(data.amount) * (operation === 'add' ? -1 : 1);
        const newBalanceAfter = (data.kassa.balance || 0) + newAmount;
        const type = newAmount < 0 ? 2 : 4
        const token = await getAuthToken();

        const descriptionType = operation === 'add'
          ? `Депозит курьеру ${courier.name} ${courier.phone} | сумма ${parseInt(newAmount)}`
          : `Курьер: ${courier.name} ${courier.phone}`

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const kassaTransactionData = {
          balanceBefore: data.kassa?.balance || 0,
          balanceAfter: newBalanceAfter,
          summa: parseInt(newAmount),
          comment: `${data.comment} | ${descriptionType} | Автор: ${roleType} ${userName}` ?? '',
          type: type,
          author: `${roleType} ${userName}`,
          dateCreated: new Date(),
          kassa: data.kassa.id,
        };

        try {
          loadingToastId = toast.loading('Добавление транзакции кассы', { autoClose: false });
          await axios.post('https://aylex.ru/api/core/kassaTransaction/', kassaTransactionData, { headers });
          toast.success('Транзакция кассы успешно добавлена');
          toast.dismiss(loadingToastId);

        } catch (error) {
          toast.dismiss(loadingToastId);
          console.error("Ошибка при добавлении транзакции в кассу:", error);
          toast.error("Ошибка при добавлении транзакции в кассу");
        }
      }

      await fetchTransactions()
    } catch (error) {
      console.error('Error adding transaction: ', error);
      handleClose()
    }
  };

  const handleButtonClick = () => {
    window.open(`/search?courierId=${id}`, '_blank');
  };

  const createOrderSortHandler = (field) => (event) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [field]: event.target.value,
    }));
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    setLastVisible(null);
  };

  const handleStatusChange = async (id, newStatus) => {
    await handleChangeStatus({
      orders: orders?.data,
      id,
      newStatus,
      dataRef: orders?.type === '',
      returnedOrderStatusLabels,
      returnedOrderAlternativeStatusLabels,
    });
  };
  const refType = (() => {
    switch (orders.type) {
      case 'orders': return orderRef;
      case 'completed': return completedOrdersRef;
      default: return returnedOrderRef;
    }
  })();

  const textForExportExcell = (() => {
    switch (orders.type) {
      case 'orders': return 'Список активных заказов курьера';
      case 'completed': return 'Список доставленных заказов курьера';
      default: return 'Список возвратов заказов курьера';
    }
  })();

  const headersForExportExcell = (() => {
    switch (orders.type) {
      case 'orders': return headers;
      default: return headersForCompletedOrders;
    }
  })();

  const dataForamtterForExportExcell = (() => {
    switch (orders.type) {
      case 'orders': return orderExcelDataFormatter;
      default: return completedOrderExcelDataFormatter;
    }
  })();

  const selectedFiltersForOrders = (() => {
    switch (orders.type) {
      case 'orders':
        return { lastCourier: id };
      case 'completed':
        return { lastCourier: id };
      default:
        return {
          lastCourier: id,
          status: 'status_rejected_courier'
        };
    }
  })();

  const handleTransactionsClick = () => {
    setShowTransactions(true);
    fetchTransactions();
  };

  const handleReturnedOrdersClick = () => {
    setShowTransactions(false);
    ferchReturnedOrders();
  };

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredData = useMemo(() => {
    if (!orders.data) return { filteredOrders: [], aggregates: { count: 0, totalCost: 0, totalRedemption: 0 } };
    const filteredOrders = orders?.data?.filter((order) =>
      fieldsToSearchOrder.some((field) => {
        const value = order[field];
        return value && value.toLowerCase().includes(search.toLowerCase());
      })
    );

    const aggregates = {
      count: filteredOrders.length,
      totalCost: filteredOrders.reduce((sum, order) => sum + (order.cost || 0), 0),
      totalRedemption: filteredOrders.reduce((sum, order) => sum + (order.redemption || 0), 0),
    };

    return { filteredOrders, aggregates };
  }, [orders.data, search]);

  const transactionTypeSort = createOrderSortHandler('type');

  const column = transActionColumns({ path: `/transaction/update` })
  const countOfOrder = countOfOrdersCard(totalCount.active, totalCount.delivered, totalCount.returned)

  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const columns = orderColumns({
    actionsConfig: actionsConfig,
    path: `/order${orders?.type !== 'orders' ? '/' : ''}${orders?.type === 'orders' ? '' : orders?.type}/details`,
    editable: true,
    customLabels: orders?.type === 'returned' ? returnedOrderStatusLabels : '',
    orderType: `${orders?.type === 'orders' ? '' : 'completed'}`
  });


  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Список курьеров' initial='Детали курьера' />
      <Title title='Детали курьера' icon={<AssignmentIndRoundedIcon fontSize='large' />} />
      <div className='container-inner'>
        <div className='courier-more-wrapper'>
          {!courier ? (
            <Loader />
          ) : (
            <div className='courier-more-card'>
              <div className='courier-card-left-side'>
                <div className='courier-card-avatar'>
                  <img
                    src={!courier?.avatar ? avatar : courier?.avatar}
                    alt={courier?.name}
                  />
                  <span className={!courier?.online ? 'courier-card-status offline' : 'courier-card-status online'}
                  ></span>
                </div>
                <h3>
                  {' '}
                  {courier?.name} {courier?.surName}
                </h3>
              </div>
              <div className='courier-card-right-side'>
                <div className='courier-card-list'>
                  <span>
                    <p>Имя:</p>
                    <p>{courier?.name}</p>
                  </span>
                  <span>
                    <p>Фамилия:</p>
                    <p>{courier?.surName}</p>
                  </span>
                  <span>
                    <p>Номер:</p>
                    <p>{courier?.phone}</p>
                  </span>
                </div>
                <div className='courier-card-list'>
                  <span style={{ width: '30%' }}>
                    <p>Рейтинг:</p>
                    <Rating
                      name='rating'
                      defaultValue={parseFloat(courier?.raiting)}
                      precision={0.5}
                      readOnly
                    />
                  </span>
                  <span>
                    <p>Город:</p>
                    <p>{courier?.cityInfo?.name}</p>
                  </span>
                  <span>
                    <p>Аккаунт:</p>
                    <p>{!courier?.active ? 'Не активный' : 'Активный'}</p>
                  </span>
                </div>
                <div className='courier-card-list'>
                  <span style={{ width: '100%' }}>
                    <p>Тип курьера:</p>
                    <p>{courier?.type}</p>
                  </span>
                  {courier?.dolya && (
                    <span style={{ width: '100%' }}>
                      <p>Доля:</p>
                      <p>{courier?.dolya}</p>
                    </span>
                  )}
                </div>
                <div className='courier-card-list'>
                  <Box display='flex' gap='7px' className='courier-button-list'>
                    {userType === 'Админ' && (
                      <>
                        <Button
                          variant='black-contained'
                          onClick={() => navigate(`/courier/update/${courier?.id}`)}
                        >
                          <EditRoundedIcon />
                        </Button>
                        <Button
                          variant='black-contained'
                          onClick={() => setOpenDeleteModal(true)}
                        >
                          <DeleteRoundedIcon />
                        </Button>
                      </>
                    )}
                    <Divider orientation='vertical' flexItem />

                    <Button variant='black-contained' onClick={handleButtonClick}>
                      Заказы
                    </Button>
                  </Box>
                </div>
              </div>
            </div>

          )}
          <div className='courier-avatar-card'>
            <div className='courier-balance-card courier-balance-card__two'>
              <div className='courier-balance-card-title'>
                <MonetizationOnRoundedIcon style={{ color: '#10B981' }} />
                <p>Balance</p>
              </div>
              <div className='courier-balance-card-data'>
                <p>{(courier?.balance ?? 0).toLocaleString()}с</p>
              </div>
              <div className='courier-balance-btn-container'>
                <Button
                  variant='contained'
                  size='md'
                  className='courier-more-btn'
                  onClick={() => handleOpen('add')}
                  disabled={!courier}
                >
                  <AddRoundedIcon />
                </Button>
                <Button
                  variant='contained'
                  size='md'
                  className='courier-more-btn'
                  onClick={() => handleOpen('subtract')}
                  disabled={!courier}
                >
                  <RemoveRoundedIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Stack direction='row' gap={2} mt={2.5}>
          {countOfOrder?.map((item, index) => (
            <BalanceCard
              key={index}
              value={item?.value}
              title={item.title}
              icon={NumbersRoundedIcon}
              iconColor={item.borderColor}
              borderColor={item.borderColor}
            />

          ))}

        </Stack>
        <Box bgcolor='#fff' boxShadow={'0 6px 6px rgb(194 202 255 / 30%)'} padding={'1rem'} mt={2.5} borderRadius={'12px'}>
          <Stack direction='row' gap={2}>
            <ToggleButtonGroup
              value={activeButton}
              exclusive
              onChange={handleButtonGroupChange}
              color="primary"
            >
              <ToggleButton value="transactions">Транзакции</ToggleButton>
              <ToggleButton value="activeToday">Активные за сегодня</ToggleButton>
              <ToggleButton value="active">Активные</ToggleButton>
              <ToggleButton value="completedToday" color='success'>Завершенные за сегодня</ToggleButton>
              <ToggleButton value="completed" color='success'>Завершенные</ToggleButton>
              <ToggleButton value="returnedToday" color='warning'>Возвраты за сегодня</ToggleButton>
              <ToggleButton value="returned" color='warning'>Возвраты</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack direction="row" gap={2} mt={2.5}>
            <BalanceCard
              title="Общая сумма заказов"
              value={aggregates.cost || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor="#10B981"
              borderColor="#10B981"
            />
            <BalanceCard
              title="Общая сумма выкупов"
              value={aggregates.redemption || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor="#635BFF"
            />
          </Stack>
        </Box>
        <div className='orders-card-wrapper' style={{ background: 'white', marginTop: '20px' }}>
          <div className='orders-card-header'>
            {!showTransactions && !isLoadingTransActions ? (
              <div className='courier-detail__btns'>
                <Stack direction='row' gap={2} sx={{ width: '100%' }} alignItems='center'>
                  <ExportToExcel
                    firestoreRef={refType}
                    selectedFilters={selectedFiltersForOrders}
                    headers={headersForExportExcell}
                    selectedDate={todayStart}
                    selectedDate2={todayEnd}
                    dateField={orders?.type === 'orders' ? 'dateCreated' : 'dateDelivered'}
                    fileName={textForExportExcell}
                    dataFormatter={dataForamtterForExportExcell}
                  />
                  <div style={{ width: '41%' }}>
                    <SearchInput
                      label='Поиск'
                      placeholder='Введите'
                      variant='outlined'
                      onChange={handleSearchOrder}
                    />
                  </div>
                </Stack>
              </div>
            ) : (
              <div className='courier-detail__btns'>
                <Stack direction='row' gap={2} sx={{ width: '100%' }} alignItems='center'>
                  <ExportToExcel
                    firestoreRef={transactionQuery}
                    selectedFilters={selectedFilters}
                    headers={headersFotTransactions2}
                    selectedDate={selectedDate}
                    selectedDate2={selectedDate2}
                    dateField='date'
                    fileName='Список транзакций курьера'
                    dataFormatter={transactionExcelDataFormatter}
                  />
                  <Box width={250}>
                    <OrderSort
                      sortKey='Сорт. по типу расхода'
                      defaultValue='Все'
                      options={typeOfTransactionLables}
                      onChange={transactionTypeSort}
                      sx={{ margin: 0, width: '100%' }}
                    />
                  </Box>
                  <TextField
                    type='date'
                    size='small'
                    focused
                    color='warning'
                    value={selectedDate || ''}
                    onChange={handleDateChange}
                    label='Начало'
                  />
                  <TextField
                    type='date'
                    size='small'
                    focused
                    color='warning'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                    label='Конец'
                  />

                </Stack>
              </div>
            )}

          </div>
          {isLoadingOrders && <Loader />}
          {isLoadingTransActions && <Loader />}
          {showTransactions && !isLoadingOrders && (
            <OrderGrid
              orders={transActions}
              columns={column}
              size={pageSize}
              page={page}
              totalRowCount={totalRowCount}
              usePagination={true}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
          {!showTransactions && !isLoadingTransActions && (
            <OrderGrid
              orders={filteredData?.filteredOrders || orders?.data}
              columns={columns}
              size={20}
              handleChangeStatus={handleStatusChange}
            />
          )}
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth='xs'>
        <DialogTitle>
          Изменение баланса {operation === 'subtract' ? '(минус баланс)' : '(плюс баланс)'}
        </DialogTitle>
        <form>
          <DialogContent>
            <Controller
              name='amount'
              control={control}
              defaultValue=''
              rules={{ required: true, min: 1 }}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin='dense'
                  id='amount'
                  label='Сумма'
                  type='number'
                  fullWidth
                  error={!!errors.amount}
                  {...field}
                />
              )}
            />
            <Controller
              name='comment'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  margin='dense'
                  id='comment'
                  label='Комментарий'
                  fullWidth
                  {...field}
                />
              )}
            />
            <Controller
              name='transactionType'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  id='outlined-select-currency' 
                  select
                  margin='dense'
                  label='Тип операции'
                  fullWidth
                  {...field}
                >
                  {operation === 'add' && (
                    <MenuItem value='deposit'>
                      Депозит
                    </MenuItem>
                  )}
                  {typeOfTransactionLables.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name='kassa'
              control={control}
              defaultValue=''
              rules={typeOfСonsumption === 'admin_minus' || typeOfСonsumption === 'deposit' ? { required: true } : { required: false }}
              render={({ field }) => (
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Выберите кассу'
                  fullWidth
                  {...field}
                  error={typeOfСonsumption === 'admin_minus' || typeOfСonsumption === 'deposit'}
                  helperText={typeOfСonsumption === 'admin_minus' || typeOfСonsumption === 'deposit' ? 'Обязательное поле!' : ''}
                  value={field.value.id || ''}
                  onChange={(e) => {
                    const selectedOption = kassa.find(option => option.id === e.target.value);
                    field.onChange(selectedOption);
                  }}
                >
                  {kassa?.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: '#000b1f' }} variant='text'>
              Отмена
            </Button>
            <Button
              type='submit'
              variant='text'
              sx={{ color: '#000b1f' }}
              onClick={handleSubmit(onSubmitTransaction)}
            >
              {operation === 'subtract' ? 'Списать' : 'Добавить'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CustomDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        event={() => handleDeleteCourier()}
        text='Вы уверены что хотите удалить курьера?'
        btnText='Удалить'
      />
    </div>
  );
};

export default Courier;
