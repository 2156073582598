import React, { useEffect, useState } from 'react';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { addDoc} from 'firebase/firestore';
import { userCollectionRef } from '../../../../utils/collectionRefs';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { Backdrop, Button, CircularProgress, Stack, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const EditKassa = ({ pageTitle }) => {
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const date = new Date();

  const { handleSubmit, reset, control, formState: { errors } } = useForm();

  useEffect(() => {
    const fetchKassaData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://aylex.ru/api/core/kassa/${id}/`);

        if (response.status === 200) {
          reset(response.data);
        } else {
          toast.error('Касса не найдена', {
            autoClose: 2000,
            hideProgressBar: false,
          });
        }
      } catch (error) {
        console.error('Ошибка загрузки кассы:', error);
        toast.error('Произошла ошибка при загрузке кассы', {
          autoClose: 2000,
          hideProgressBar: false,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchKassaData();
  }, [id, reset]);


  const handleEditKassa = async (data) => {
    setOpen(true);

    const token = localStorage.getItem('authToken'); 

    const historyData = {
      date: date,
      description: `${userType} ${userName} отредактировал кассу ${data.name}`
    };

    try {
      await axios.patch(
        `https://aylex.ru/api/core/kassa/${id}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await addDoc(userCollectionRef, historyData);

      setOpen(false);
      navigate('/bank');

      toast.success('Касса успешно отредактирована', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } catch (e) {
      setOpen(false);
      toast.error('Произошла ошибка при редактировании кассы', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <div className='container'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Редактировать кассу' />
      <Title title='Редактировать кассу' icon={<AddCardRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <div className='addCourier-wrapper kassa'>
          <div className='order-block-head'>
            <h3>Редактирование кассы</h3>
          </div>
          {loading ? ( // Отображаем индикатор загрузки, пока данные загружаются
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color='inherit' />
            </Backdrop>
          ) : (
            <form className='add-courier-form'>
              <Stack direction='column' gap={2}>
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: 'Обязательное поле!' }}
                  render={({ field }) => (
                    <TextField
                      label='Название кассы'
                      multiline
                      variant='outlined'
                      size='small'
                      helperText={errors?.name?.message}
                      error={!!errors?.name}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='comment'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label='Комментарии'
                      variant='outlined'
                      size='small'
                      {...field}
                    />
                  )}
                />
              </Stack>

              <Button
                onClick={handleSubmit((data) => handleEditKassa(data))}
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
                sx={{ marginTop: '8px' }}
              >
                Обновить кассу
              </Button>
            </form>
          )}

        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}

export default EditKassa;
