import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getDocs, query, where, getCountFromServer, orderBy, getAggregateFromServer, sum } from 'firebase/firestore';
import { Button, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import { orderColumns } from '../Orders/helpers/orderColumns';
import { handleCopyOrder } from '../Orders/helpers/handleCopyOrder';
import { headers, headersForCompletedOrders, returnedOrderStatusLabels } from '../Orders/constants';
import { archivedOrdersRef, canceledOrdersRef, citiesRef, completedOrdersRef, couriersRef, orderRef, returnedOrderRef } from '../../../../utils/collectionRefs';
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { Loader } from '../../../../components/Loader/Loader';
import OrderGrid from '../../../../components/OrderGrid ';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import { DatePicker } from '@mui/x-date-pickers';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import InfoIcon from '@mui/icons-material/Info';
import './scss/Search.scss'
import ExportToExcel from '../../../../components/Buttons/ExportToExcell';
import { completedOrderExcelDataFormatter, orderExcelDataFormatter, returnedOrderExcelDataFormatter } from '../Orders/helpers/excelDataFormatter';
import DashboardChart from './helpers/ChartDashboard';
import { getCourierName } from '../Orders/helpers/getCourierName';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { ExportToExcelButtons } from './helpers/ExportExcel';

const Search = ({ pageTitle }) => {
  const [couriers, setCouriers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersDone, setOrdersDone] = useState([]);
  const [ordersReturned, setOrdersReturned] = useState([]);
  const [ordersCancelled, setOrdersCancelled] = useState([]);
  const [ordersDeleted, setOrdersDeleted] = useState([]);
  const [aggregates, setAggregates] = useState({});

  const ordersTabs = [
    orders,
    ordersDone,
    ordersReturned,
    ordersCancelled,
    ordersDeleted
  ];
  const ordersTabsRef = [
    orderRef,
    completedOrdersRef,
    returnedOrderRef,
    canceledOrdersRef,
    archivedOrdersRef,
  ];

  const orderDetailPath = [
    '/order/details',
    '/order/completed/details',
    '/order/returned/details',
    '/order/cancelled/details',
    '/order/details',
  ];
  const excelFileName = [
    'Активные заказы',
    'Доставленные заказы',
    'Возвратные заказы',
    'Отмененные заказы',
    'Удаленные заказы',
  ];

  const excelDataFormatter = [
    orderExcelDataFormatter,
    completedOrderExcelDataFormatter,
    returnedOrderExcelDataFormatter,
    orderExcelDataFormatter,
  ];

  const [cities, setCities] = useState([])
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false)

  const currentData = useMemo(() => {
    const keys = ['active', 'completed', 'returned', 'cancelled', 'deleted'];
    return aggregates[keys[tabIndex]] || { sum: 0, redemption: 0 };
  }, [tabIndex, aggregates]);

  const [counts, setCounts] = useState({
    active: 0,
    completed: 0,
    returned: 0,
    cancelled: 0,
    deleted: 0,
  })
  const { handleSubmit, control, reset, setValue, watch } = useForm({ mode: 'onChange' });

  const navigate = useNavigate()
  const location = useLocation()

  const phoneNumber = watch('phoneNumber');
  const searchType = watch('searchType');

  const onSubmit = async (data) => {
    setLoading(true);

    const { phoneNumber, searchType, dateFrom, dateTo, cityTo, cityFrom, dateType } = data;
    const filters = []
    const completedOrderFilters = []

    if (dateFrom) {
      const field = dateType === 'dateDelivered' ? 'dateDelivered' : 'dateCreated';
      filters.push(where('dateCreated', '>=', dateFrom.toDate()));
      completedOrderFilters.push(where(field, '>=', dateFrom.toDate()));
    }
    if (dateTo) {
      const field = dateType === 'dateDelivered' ? 'dateDelivered' : 'dateCreated';

      filters.push(where('dateCreated', '<=', dateTo.toDate()));
      completedOrderFilters.push(where(field, '<=', dateTo.toDate()));
    }
    if (cityFrom) {
      filters.push(where('cityFrom', '==', cityFrom));
      completedOrderFilters.push(where('cityFrom', '==', cityFrom));
    }
    if (cityTo) {
      filters.push(where('cityTo', '==', cityTo));
      completedOrderFilters.push(where('cityTo', '==', cityTo));
    }
    if (phoneNumber) {
      if (searchType === 'couriersForFilter') {
        filters.push(where(searchType, 'array-contains', phoneNumber));
        completedOrderFilters.push(where('courierTwo', '==', phoneNumber));
      } else {
        filters.push(where(searchType, '==', phoneNumber));
        completedOrderFilters.push(where(searchType, '==', phoneNumber));
      }
    }
    filters.push(orderBy('dateCreated', 'desc'))

    const queries = [
      query(orderRef, ...filters),
      query(completedOrdersRef, ...completedOrderFilters),
      query(returnedOrderRef, ...filters),
      query(canceledOrdersRef, ...filters),
      query(archivedOrdersRef, ...filters),
    ];
    const aggregateQueries = [
      query(orderRef, ...filters, where('redemption', '>=', 0)),
      query(completedOrdersRef, ...completedOrderFilters, where('redemption', '>=', 0)),
      query(returnedOrderRef, ...filters, where('redemption', '>=', 0)),
      query(canceledOrdersRef, ...filters, where('redemption', '>=', 0)),
      query(archivedOrdersRef, ...filters, where('redemption', '>=', 0)),
    ]

    try {
      const [
        activeCount,
        completedCount,
        returunedCount,
        cancelledCount,
        deletedCount
      ] = await Promise.all(
        queries.map(q => getCountFromServer(q))
      );
      setCounts({
        active: activeCount.data().count,
        completed: completedCount.data().count,
        returned: returunedCount.data().count,
        cancelled: cancelledCount.data().count,
        deleted: deletedCount.data().count,
      });
      const [active, completed, returned, cancelled, deleted] = await Promise.all(
        queries.map(q => getDocs(q))
      );

      const ordersData = active.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
      const ordersDoneData = completed.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
      const ordersReturnedData = returned.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
      const ordersCancelledData = cancelled.docs.map(doc => ({ ...doc?.data(), id: doc.id }));
      const ordersDeletedData = deleted.docs.map(doc => ({ ...doc?.data(), id: doc.id }));

      const [
        activeRedemption,
        completedRedemption,
        returnedRedemption,
        cancelledRedemption,
        deletedRedemption,
      ] = await Promise.all(
        aggregateQueries.map(q => getAggregateFromServer(q, { redemption: sum('redemption'), sum: sum('cost') }))
      );
      setAggregates({
        active: activeRedemption.data(),
        completed: completedRedemption.data(),
        returned: returnedRedemption.data(),
        cancelled: cancelledRedemption.data(),
        deleted: deletedRedemption.data(),
      });

      setOrders(ordersData);
      setOrdersDone(ordersDoneData);
      setOrdersReturned(ordersReturnedData);
      setOrdersCancelled(ordersCancelledData);
      setOrdersDeleted(ordersDeletedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const courierId = params.get('courierId');
    if (courierId) {
      setValue('phoneNumber', courierId);
      setValue('searchType', 'couriersForFilter');
    }
  }, [location.search, setValue]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const courierId = params.get('courierId');
    if (courierId && searchType === 'couriersForFilter') {
      handleSubmit(onSubmit)();
    }
  }, [searchType, phoneNumber, handleSubmit, location.search]);

  useEffect(() => {
    if (!couriers?.length || !orders) return;

    setOrders((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        const courierOneInfo = getCourierName(order.courierOne, couriers);
        const courierTwoInfo = getCourierName(order.courierTwo, couriers);

        return {
          ...order,
          courierOneName: courierOneInfo.name,
          courierTwoName: courierTwoInfo.name,
        };
      });

      if (JSON.stringify(prevOrders) === JSON.stringify(updatedOrders)) {
        return prevOrders;
      }
      return updatedOrders;
    });
    setOrdersDone((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        const courierOneInfo = getCourierName(order.courierOne, couriers);
        const courierTwoInfo = getCourierName(order.courierTwo, couriers);

        return {
          ...order,
          courierOneName: courierOneInfo.name,
          courierTwoName: courierTwoInfo.name,
        };
      });

      if (JSON.stringify(prevOrders) === JSON.stringify(updatedOrders)) {
        return prevOrders;
      }
      return updatedOrders;
    });
    setOrdersReturned((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        const courierOneInfo = getCourierName(order.courierOne, couriers);
        const courierTwoInfo = getCourierName(order.courierTwo, couriers);

        return {
          ...order,
          courierOneName: courierOneInfo.name,
          courierTwoName: courierTwoInfo.name,
        };
      });

      if (JSON.stringify(prevOrders) === JSON.stringify(updatedOrders)) {
        return prevOrders;
      }
      return updatedOrders;
    });
    setOrdersCancelled((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        const courierOneInfo = getCourierName(order.courierOne, couriers);
        const courierTwoInfo = getCourierName(order.courierTwo, couriers);

        return {
          ...order,
          courierOneName: courierOneInfo.name,
          courierTwoName: courierTwoInfo.name,
        };
      });

      if (JSON.stringify(prevOrders) === JSON.stringify(updatedOrders)) {
        return prevOrders;
      }
      return updatedOrders;
    });
    setOrdersDeleted((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        const courierOneInfo = getCourierName(order.courierOne, couriers);
        const courierTwoInfo = getCourierName(order.courierTwo, couriers);

        return {
          ...order,
          courierOneName: courierOneInfo.name,
          courierTwoName: courierTwoInfo.name,
        };
      });

      if (JSON.stringify(prevOrders) === JSON.stringify(updatedOrders)) {
        return prevOrders;
      }
      return updatedOrders;
    });
  }, [couriers, orders, ordersDone, ordersReturned, ordersDeleted, ordersCancelled]);

  useEffect(() => {
    fetchData(citiesRef, 'name', 'asc', setCities);
    fetchData(couriersRef, 'name', 'asc', setCouriers);
  }, []);

  const fetchData = async (collectionRef, sortField, sortOrder, setData) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder))
      );
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }));
      setData(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleTabChange = useCallback((event, newIndex) => {
    setTabIndex(newIndex);
  }, []);


  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'Редактировать',
      icon: <NoteAltRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/update/${params.id}`),
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`)
    },
  ];

  const currentOrders = ordersTabs[tabIndex] || [];
  const currentOrderRef = ordersTabsRef[tabIndex] || [];
  const currentExcelFileName = excelFileName[tabIndex] || [];
  const currentExcelDataFormatter = excelDataFormatter[tabIndex] || [];
  const currentOrderDetailPath = orderDetailPath[tabIndex] || [];


  const handleReset = () => {
    reset({
      phoneNumber: '+996',
      cityFrom: '',
      cityTo: '',
      dateFrom: null,
      dateTo: null,
      searchType: 'sender',
    });
    setOrders([]);
    setOrdersDone([]);
    setOrdersReturned([]);
    setCounts({ active: 0, completed: 0, returned: 0 });
  };

  const dateFormatterWithoutYear = useCallback((timestamp) => {
    if (!timestamp || !timestamp.toDate) {
      return '';
    }

    const date = timestamp.toDate();
    if (!date) {
      return '';
    }

    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    return `${month} ${day}`;
  }, []);

  const getOrdersDataAndLabel = () => {
    switch (tabIndex) {
      case 0:
        return { data: orders, label: 'Все заказы' };
      case 1:
        return { data: ordersDone, label: 'Выполненные заказы' };
      case 2:
        return { data: ordersReturned, label: 'Возвращённые заказы' };
      case 3:
        return { data: ordersCancelled, label: 'Отменённые заказы' };
      case 4:
        return { data: ordersDeleted, label: 'Удалённые заказы' };
      default:
        return { data: [], label: 'Нет данных' };
    }
  };

  const columns = orderColumns({
    actionsConfig: actionsConfig,
    path: currentOrderDetailPath,
    customLabels: tabIndex === 2 ? returnedOrderStatusLabels : undefined,
    orderType: tabIndex === 1 ? 'completed' : '',
  })
  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Искать везде' />
        <Title title='Искать везде' icon={<FindInPageRoundedIcon fontSize='meduim' />} />
        <div className="container-inner">
          <Stack direction='row' gap={1}>
            <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="search-input-block">
                <Controller
                  name='phoneNumber'
                  defaultValue='+996'
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref, ...rest } }) => (
                    <TextField
                      label='Номер телефона'
                      size='small'
                      type="search"
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        const trimmedValue = value.trim();
                        setValue('phoneNumber', trimmedValue);
                      }}
                      inputRef={ref}
                      {...rest}
                    />
                  )}
                />

                <Stack direction='row' gap={2}>
                  <Controller
                    name='cityFrom'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label='Откуда'
                        size='small'
                        select
                        fullWidth
                      >
                        <MenuItem value='Все'>Все</MenuItem>
                        {cities?.map((option) => (
                          <MenuItem key={option.name} value={parseInt(option.id)}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name='cityTo'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label='Куда'
                        fullWidth
                        size='small'
                      >
                        <MenuItem value='Все'>Все</MenuItem>
                        {cities?.map((option) => (
                          <MenuItem key={option.name} value={parseInt(option.id)}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Stack>
                <Stack direction='row' gap={2}>
                  <Controller
                    name='dateFrom'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DatePicker
                        label="От"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                  <Controller
                    name='dateTo'
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DatePicker
                        label="До"
                        value={field.value}
                        onChange={(date) => field.onChange(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />

                </Stack>
                <Controller
                  name='searchType'
                  control={control}
                  defaultValue='sender'
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="sender"
                        control={<Radio />}
                        label="Отправитель"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="receiver"
                        control={<Radio />}
                        label="Получатель"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="couriersForFilter"
                        control={<Radio />}
                        label="Курьер"
                        labelPlacement="top"
                      />
                    </RadioGroup>
                  )}
                />
                <Controller
                  name='dateType'
                  control={control}
                  defaultValue='dateDelivered'
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <FormControlLabel
                        value="dateCreated"
                        control={<Radio />}
                        label="Дате создания"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="dateDelivered"
                        control={<Radio />}
                        label="Дата доставки"
                        labelPlacement="top"
                      />
                    </RadioGroup>
                  )}
                />
                <Stack direction='row' gap={1}>
                  <Button type='submit' variant='black-contained' fullWidth>
                    Запрос
                  </Button>
                  <Tooltip title="Сбросить фильтры" placement='right' arrow>
                    <Button
                      variant='contained'
                      onClick={handleReset}
                      sx={{ width: '10%' }}
                      color='inherit'
                    >
                      <FilterAltOffRoundedIcon />
                    </Button>
                  </Tooltip>
                </Stack>
              </div>
            </form>
            <div className="dashboard-block" style={{ width: '70%' }}>
              <div className='dashboard-block-top-title'>
                <h2>Статистика заказов</h2>
                <Tooltip
                  title='Здесь отображается количество заказов по статусам: активные, доставленные и возвраты.'
                  placement='left'
                  arrow
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <DashboardChart
                tabIndex={tabIndex}
                orders={orders}
                ordersDone={ordersDone}
                ordersReturned={ordersReturned}
                ordersCancelled={ordersCancelled}
                ordersDeleted={ordersDeleted}
                dateFormatterWithoutYear={dateFormatterWithoutYear}
              />
            </div>
          </Stack>
          <Stack direction='row' gap={1} mt={2.5}>
            <BalanceCard
              title='Общая сумма заказов'
              value={currentData?.sum || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor='#10B981'
              borderColor='#10B981'
            />
            <BalanceCard
              title='Общая сумма выкупов'
              value={currentData?.redemption || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor='#635BFF'
            />
          </Stack>
          <div style={{ marginTop: '10px' }} />
          <div className="orders-card-wrapper">
            <div className="orders-card-header">
              <div className='orders-card-parent__btns'>
                <ExportToExcelButtons ordersData={getOrdersDataAndLabel().data} label={getOrdersDataAndLabel().label} />

                {/* <ExportToExcel
                  firestoreRef={currentOrderRef}
                  selectedFilters={{
                    cityFrom: getValues('cityFrom'),
                    cityTo: getValues('cityTo'),
                    [getValues('searchType')]: getValues('phoneNumber'),
                  }}
                  selectedDate={getValues('dateFrom')}
                  selectedDate2={getValues('dateTo')}
                  dateField={tabIndex === 1 ? 'dateDelivered' : 'dateCreated'}
                  fileName={currentExcelFileName}
                  headers={tabIndex === 0 ? headers : headersForCompletedOrders}
                  operators={getValues('searchType') === 'couriersForFilter' ? 'array-contains' : '=='}
                  dataFormatter={currentExcelDataFormatter}
                /> */}
                <div>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    scrollButtons='auto'
                    variant='scrollable'
                  >
                    <Tab label={`Активные (${counts.active})`} />
                    <Tab label={`Завершенные (${counts.completed})`} />
                    <Tab label={`Возвраты (${counts.returned})`} />
                    <Tab label={`Отмененные (${counts.cancelled})`} />
                    <Tab label={`Удаленные (${counts.deleted})`} />
                  </Tabs>
                </div>
              </div>
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={currentOrders}
                columns={columns}
                size={20}
                usePagination={false}
              />}
          </div>
        </div>
      </div>
    </>
  )
}

export default Search