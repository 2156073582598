import React, { useState } from 'react'
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import { Helmet } from 'react-helmet';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { addDoc } from 'firebase/firestore';
import { userCollectionRef } from '../../../../utils/collectionRefs';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { Backdrop, Button, CircularProgress, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../configs/axiosInstance';

const AddKassa = ({ pageTitle }) => {
  const [open, setOpen] = useState(false)
  const { handleSubmit, reset, control, formState: { errors } } = useForm();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const navigate = useNavigate()
  const date = new Date()

  const handleAddKassa = async (data) => {
    setOpen(true);

    const kassaData = {
      ...data,
      dateCreated: date,
      uid: date.getTime(),
      balance: parseInt(data?.balance) || 0,
    }

    const historyData = {
      date: date,
      description: `${userType} ${userName} добавил кассу ${data.name}`
    }

    try {
      await axiosInstance.post('/core/kassa/', kassaData)

      await addDoc(userCollectionRef, historyData)

      toast.success('Касса успешно добавлена', {
        autoClose: 2000,
        hideProgressBar: false,
      });

      navigate('/bank');
    } catch (e) {
      toast.error('Произошла ошибка при добавлении кассы', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    } finally {
      setOpen(false);
      reset();
    }
  };

  return (
    <div className='container'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Статистика' initial='Добавить кассу' />
      <Title title='Добавить кассу' icon={<AddCardRoundedIcon fontSize='meduim' />} />
      <div className="container-inner">
        <div className='addCourier-wrapper kassa'>
          <div className='order-block-head'>
            <h3>добавление кассы</h3>
          </div>
          <form className='add-courier-form'>
            <Stack direction='column' gap={2}>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                rules={{ required: 'Обьязательное полe!' }}
                render={({ field }) => (
                  <TextField
                    label='Название кассы'
                    multiline
                    variant='outlined'
                    size='small'
                    helperText={errors?.name?.message}
                    error={errors?.name && true}
                    {...field}
                  />
                )}
              />
              <Controller
                name='comment'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    label='Комментарии'
                    variant='outlined'
                    size='small'
                    {...field}
                  />
                )}
              />
              <Controller
                name='balance'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    label='Баланс кассы'
                    variant='outlined'
                    size='small'
                    type='number'
                    {...field}
                  />
                )}
              />
            </Stack>

            <Button
              onClick={handleSubmit((data) => handleAddKassa(data))}
              size='large'
              variant='contained'
              style={{ background: '#000b1f' }}
              sx={{ marginTop: '8px' }}
            >
              Создать кассу
            </Button>
          </form>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

export default AddKassa