import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export const useDetailKassa = (id) => {
  const [kassa, setKassa] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchDetailKassaData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://aylex.ru/api/core/kassa/${id}/`);
      setKassa(response.data);
    } catch (error) {
      console.error('Error fetching kassa data:', error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchDetailKassaData();
  }, [fetchDetailKassaData]);

  return { kassa, loading, refetch: fetchDetailKassaData };
};