import React from 'react'
import OrderGrid from '../../../../../components/OrderGrid '
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../../components/Header/Header'
import { Title } from '../../../../../components/Title/Title'
import PaletteRoundedIcon from '@mui/icons-material/PaletteRounded';
import { useFetchData } from '../../../../../hooks/useFetchData'
import { ColorColumn } from '../constants/MarketPlaceColumn'

export const Colors = ({ pageTitle }) => {
  const { data, loading, error } = useFetchData("/category/color/");
  const navigate = useNavigate()
  return (
    <>
      <div className="containerr">
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Cтатистика' initial='Список цветов' />
        <Title
          title={'Список цветов'}
          icon={<PaletteRoundedIcon fontSize='24' />}
        />
        <div className="container-inner">
          {error ? <h1>Error :(</h1> : (
            <div className="orders-card-wrapper">
              <div className="orders-card-header">
                <div className="rders-card-parent">
                  <div className="orders-card-parent__btns">
                    <Button variant='black-contained' onClick={() => navigate('/colors/add')}>
                      Добавить +
                    </Button>
                  </div>
                </div>
              </div>
              <OrderGrid
                orders={data}
                columns={ColorColumn}
                loading={loading}
                size={20}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
