import React from 'react';
import './Header.scss';
import { MdNavigateNext } from 'react-icons/md';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { IconButton, Tooltip, Link as MuiLink, Breadcrumbs, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSidebar } from '../../provider/SidebarProvider';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';

export const Header = ({ previous, initial }) => {
  const navigate = useNavigate()
  const { isCollapsed, toggleSidebar } = useSidebar();

  return (
    <>
      <div className="main-header">
        <div className="header-page-navigation">
          {/* <Breadcrumbs
          separator={<MdNavigateNext size={20} />}
          aria-label="breadcrumb"
        >
          <MuiLink
            component={Link}
            to="/"
            underline="hover"
            color="inherit"
          >
            Home
          </MuiLink>
          {previous && (
            <MuiLink
              component={Link}
              to={`/${previous.toLowerCase()}`}
              underline="hover"
              color="inherit"
            >
              {previous}
            </MuiLink>
          )}
          <Typography color="text.primary">{initial}</Typography>
        </Breadcrumbs> */}
          <div>
            <h3>{previous}</h3>
            <MdNavigateNext />
            <h3>{initial}</h3>
          </div>
        </div>

        <div>
          <Tooltip
            title={isCollapsed ? "Открыть боковую панель" : "Закрыть боковую панель"}
            placement="top"
            arrow
            placeholder=""
          >
            <IconButton onClick={toggleSidebar} className="toggle-sidebar-button">
              {isCollapsed ? <MenuTwoToneIcon /> : <MenuOpenTwoToneIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Часто задавеамы вопросы" placement="top" arrow placeholder="">
            <IconButton onClick={() => navigate("/faq")}>
              <HelpRoundedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>

      </div>
    </>
  );
};
