import copy from 'clipboard-copy';
import { dateFormatter } from '../../../../../utils/dateFormatter';
import { packageTypeLabels, paymentMethods, statusLabels } from '../../../../../utils';
import { toast } from 'react-toastify';

export const handleCopyOrder = async (params) => {
  const {
    senderPhone,
    addressFrom,
    receiverName,
    receiverPhone,
    addressTo,
    dateCreated,
    status,
    packageType,
    tariff,
    whoPays,
    paymentMethod,
    paymentStatus,
    cost,
    redemption,
    comments,
  } = params?.row;
  const copyData = {
    Отправитель: {
      'Имя отправителя': 'senderName',
      'Телефон отправителя': senderPhone,
      Город: addressFrom?.cityName || null,
      'Район/Село': addressFrom?.districtName || null,
      Адрес: addressFrom?.address,
    },
    Получатель: {
      'Имя получателя': receiverName,
      'Телефон получателя': receiverPhone,
      Город: addressTo?.cityName || null,
      'Район/Село': addressTo?.districtName || null,
      Адрес: addressTo?.address,
    },
    'Детали заказа': {
      ID: params.id,
      PID: params?.pid || null,
      'Дата создания': dateFormatter(dateCreated?.toDate()),
      'Статус заказа': statusLabels[status],
      'Тип посылки': packageTypeLabels[packageType],
      'Тариф заказа': tariff?.name,
      'Платежное лицо': (() => {
        switch (whoPays) {
          case 1:
            return 'Отправитель';
          case 2:
            return 'Получатель';
          default:
            return 'Совместно';
        }
      })(),
      'Метод оплаты': paymentMethods[paymentMethod],
      'Статус оплаты': !paymentStatus ? 'Не оплачен' : 'Оплачен',
      Цена: !cost ? tariff?.cost : cost,
      Выкуп: redemption,
      Комментарии: comments,
    },
  };
  const resultString = JSON.stringify(copyData, null, 2);
  try {
    copy(resultString);
    toast.success('Данные успешно скопированы', {
      autoClose: 2000,
      hideProgressBar: false,
    });
  } catch (error) {
    console.error(error);
    toast.error('Не удалось скопировать данные в буфер обмена:', {
      autoClose: 2000,
      hideProgressBar: false,
    });
  }
};